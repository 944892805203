import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import ErrorMessages from "./ErrorMessages";
import UploadSuccessScreen from "./UploadSuccessScreen";
import FooterButtons from "./FooterButtons";
import useUploadCSVForm from "../../../hooks/useUploadCSVForm";
import Dropzone from "./Dropzone";
import CSVList from "./CSVList";
import DragBackDrop from "./DragBackDrop";

const schema = Yup.object().shape({
  formDataType: Yup.string(),
  formFiles: Yup.array().required(),
});

export default function UploadCSVForm() {

  const {handleDrag, handleUpload, uploadDone} = useUploadCSVForm()

  return (
    <>
      {uploadDone ? <UploadSuccessScreen/> : <>
        <DragBackDrop/>
        <div className='w-full flex flex-col h-under-header max-h-800:overflow-y-auto overflow-x-hidden' onDragEnter={handleDrag}>
          <Formik
            initialValues={{
              formDataType: 'Purchasing Data',
              formFiles: [],
            }}
            validationSchema={schema}
            onSubmit={(values, {setErrors, resetForm}) => {
              const {formDataType, formFiles} = values;
              if (!formFiles.length) setErrors({formFiles: 'At least one CSV file is required'});
              else {
                handleUpload(formDataType, formFiles, setErrors, resetForm);
              }
            }}
          >
            {({errors, setValues}) => (
              <>
                <ErrorMessages errors={errors}/>
                <Form className='flex flex-col flex-grow'>
                  <div className='flex flex-row gap-[40px] justify-center items-center flex-grow max-h-800:py-[10px]'>
                    <CSVList/>
                    <Dropzone/>
                  </div>
                  <div className='w-full h-0 border border-DarkStone'></div>
                  <FooterButtons setValues={setValues}/>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </>}
    </>
  );
}
