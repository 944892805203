import useUploadCSVForm from "../../../hooks/useUploadCSVForm";
import CSVDropdown from "./CSVDropdown";
import UploadCSVCard from "./UploadCSVCard";
import {checkIfArrayOfObjectsIncludesValue} from "../../../utils/utils";

export default function CSVList() {
  const {files, brokenHeadersFiles, emptyFiles, wrongTypeFiles, exceededRowsFiles, badDateFiles, missingDataFiles} = useUploadCSVForm();

  return <div
    className='flex flex-col justify-start items-center rounded-[12px] bg-white xl:w-[440px] w-[45%] h-[536px] shadow-[0_5px_18px_rgba(0,0,0,0.1)]'>
    <div className='flex my-[32px]'>
      <CSVDropdown options={['Purchasing Data', 'Sales Data']}/>
    </div>
    <div className='w-full h-[1px] bg-DarkStone'></div>
    {files?.length ? <div className='w-full h-full overflow-hidden mr-[17px] mt-[17px]'>
      {files?.length > 0 && <div
        className='max-h-[410px] list-scrollbar'>
        <ul className='w-full'>
          {files?.map((file) => (
            <UploadCSVCard
              key={file.id}
              file={file}
              error={brokenHeadersFiles?.includes(file.name) || emptyFiles?.includes(file.name) || wrongTypeFiles?.includes(file.name) || exceededRowsFiles?.includes(file.name) || checkIfArrayOfObjectsIncludesValue(badDateFiles, 'fileName', file.name ) || checkIfArrayOfObjectsIncludesValue(missingDataFiles, 'fileName', file.name)}
            />
          ))}
        </ul>
      </div>}
    </div> : <div className='w-full h-full flex flex-col justify-center items-center '>
      <p
        className='h-[31px] main-font-bolded text-[24px] leading-[20px] mb-[7px] text-center text-Black'>
        No uploaded files
      </p>
      <p
        className='h-[48px] font-medium leading-[20px] text-Grey text-center secondary-font'>
        Please select a file <br/> to view it here.
      </p>
    </div>}
  </div>
}