/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { SandboxContext } from '../../../../context/SandboxContext'
import SandboxToolTip from '../../../templates/SandboxToolTip';
import { useInView } from "react-intersection-observer";

export default function Top100Emissions({ fetchData }) {
    const { searchedProducts, selectedProducts } = useContext(SandboxContext);

    const { ref, inView } = useInView({
        threshold: 0,
    });

    useEffect(() => {
        if (inView) {
            if (selectedProducts.length === 0) {
                fetchData();
            }
        }
    }, [inView]);

    return (
        <div>
            {searchedProducts.length > 0 &&
                <div className="grid grid-cols-9 mt-[24px] text-base text-left secondary-font text-[15px]">
                    <div className="pl-[24px] col-span-7">Product Name</div>
                    <div>Quantity KG</div>
                    <div>CO2 KG in Period</div>
                </div>}
            <div className={`list-scrollbar secondary-font`}>
                {searchedProducts.map((product, index) => (
                    <div
                        ref={index === searchedProducts.length - 1 ? ref : null}
                        key={product.product_id}>
                        <div className="min-h-[80px] grid grid-cols-9 items-center mb-[24px] text-[15px] bg-white text-Grey text-left border rounded-xl shadow-sm leading-[20px]">
                            <div className='col-span-7'>
                                <SandboxToolTip text={product.product} details={'left-[170px] top-[-30px] w-[300px]'}>
                                    <div className="pl-[24px] leading-5 main-font-bolded text-black text-base">
                                        {product.product}
                                    </div>
                                </SandboxToolTip>
                            </div>
                            <div>{parseFloat(product.weight / 1000).toFixed(2)}</div>
                            <div>{parseFloat(product.totalCo2).toFixed(2)}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
