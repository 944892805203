import {useEffect} from "react";

export default function useOnClickOutside(ref, handler, _ignore) {
  useEffect(
    () => {
      function listener (event) {
        if (!ref.current || (ref.current.contains(event.target) || _ignore?.current.includes(event.target))) {
          return;
        }
        handler(event);
      }

      document.addEventListener("mousedown", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
      };
    },

    [ref, handler, _ignore]
  );
}