/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import fetchSavedSandboxes from "../../../utils/fetchSavedSandboxes";
import Spinner from "../../templates/Spinner";
import moment from "moment";
import fetchGetSavedSandboxes from "../../../utils/fetchGetSavedSandbox";
import { SandboxContext } from "../../../context/SandboxContext";
import { useNavigate } from "react-router-dom";
import fetchDeleteSandbox from "../../../utils/fetchDeleteSandbox";
import ConfirmationRemoveModal from "./Modals/ConfirmationRemoveModal";

export default function SavedSandboxes() {
  const [sentRequest, setSentRequest] = useState(false);
  const [sandboxes, setSandboxes] = useState([]);
  const [sandboxToDelete, setSandboxToDelete] = useState(null);
  const { resetContext, setMarginImpactDetails, setSandboxScenario, sandboxScenario, sandbox, setSandbox, setSelectedProducts, setEditedProducts, setCompareProducts, setShowConfirmationRemoveModal  } = useContext(SandboxContext);

  const navigate = useNavigate();

  const getUserSandboxes = async () => {
    setSentRequest(true);
    const result = await fetchSavedSandboxes();
    setSentRequest(false);

    if (result) {
      setSandboxes(result);
    }
  }

  const formatDate = (date) => {
    //get the day and hour with moment like DD.MM.YYYY HH:mm
    const formattedDate = moment(date).format("DD.MM.YYYY HH:mm");
    return formattedDate;
  }

  const getSandboxDetails = async (id) => {
    const result = await fetchGetSavedSandboxes(id);

    if (result) {
      setMarginImpactDetails({ 
        dataRange: [result.general.dateRangeStart, result.general.dateRangeEnd],
        revenue: result.general.revenue,
        costOfSale: result.general.costOfSale,
        totalCo2: result.general.totalCo2,
        query: result.general.query,
      });

      setSandboxScenario({
        ...sandboxScenario,
        weight: result.originalMix.quantity.toFixed(2),
        price: result.originalMix.price.toFixed(2),
        co2perkg: result.originalMix.co2perkg.toFixed(2),
        totalCo2: result.originalMix.co2InPeriod.toFixed(2),
      })

      setSandbox({
        ...sandbox,
        id: id,
        name: result.general.name,
        co2perkg: result.sandboxMix.co2perkg.toFixed(2),
        totalCo2: result.sandboxMix.co2InPeriod.toFixed(2),
        price: result.sandboxMix.price.toFixed(2),
        weight: (result.sandboxMix.quantity * (100 / result.general.toSandboxPercentage)).toFixed(2),
        toSandboxPercentage: result.general.toSandboxPercentage,
        percentage: 100
      });

      const productsSelected = result.original.map((product, index) => {
        return {
          caseSize: result.original[index].caseSize,
          sku: result.original[index].sku,
          date: result.original[index].emission.date,
          percentage: 100,
          price: 0,
          product: result.original[index].emission.title,
          product_id: result.original[index].emission.id,
          selected: true,
          supplier: result.original[index].supplier,
          co2perkg: result.original[index].co2perkg,
          weight: result.original[index].emission.weight,
          totalCo2: result.original[index].co2perkg * result.original[index].emission.weight,
        }
      })

      const productsEdited = result.original.map((product, index) => {
        return {
          caseSize: result.original[index].caseSize,
          sku: result.original[index].sku,
          date: result.original[index].emission.date,
          percentage: result.original[index].percentage,
          price: result.original[index].price,
          product: result.original[index].emission.title,
          product_id: result.original[index].emission.id,
          selected: true,
          supplier: result.original[index].supplier,
          co2perkg: result.original[index].co2perkg,
          weight: result.original[index].quantity,
          totalCo2: (result.original[index].co2perkg * result.original[index].quantity) / 1000,
        }
      })

      const productsCompared = result.sandbox.map((product, index) => {
        return {
          category: result.sandbox[index].substitution.category_product,
          co2perkg: result.sandbox[index].co2perkg,
          collectionType: result.sandbox[index].collectionType,
          percentage: result.sandbox[index].percentage,
          price: result.sandbox[index].price,
          product: result.sandbox[index].substitution.title || result.sandbox[index].substitution.product_name,
          product_id: result.sandbox[index].substitution.id,
          selected: true,
          totalCo2: result.sandbox[index].co2InPeriod,
          weight: result.sandbox[index].quantity,
        }
      });

      setSelectedProducts(productsSelected);
      setEditedProducts(productsEdited);
      setCompareProducts(productsCompared);
    }
  }

  const viewSandbox = async (id) => {
    await getSandboxDetails(id);
    navigate(`/sandbox/margin-impact`);
  }

  const editSandbox = async (id) => {
    await getSandboxDetails(id);
    navigate(`/sandbox/selected-products`);
  }

  const handleDeleteSandbox = async (id) => {
    if (id) {
      const result = await fetchDeleteSandbox(id);

      if(result?.data?.id) {
        const filteredSandboxes = sandboxes.filter((sandbox) => sandbox.id !== id);
        setSandboxes(filteredSandboxes);
        setSandboxToDelete(null);
      }
    }
  }

  useEffect(() => {
    resetContext();
    getUserSandboxes();
  }, []);
 
  return (
    <div className="mx-[64px] mt-[48px] flex flex-col h-under-header max-h-800:overflow-y-auto list-scrollbar">
      {sentRequest && <Spinner />}
      {sandboxes.map((sandbox) => (
        <div key={sandbox.id} className="flex flex-row justify-between w-full h-[80px] bg-white box-shadow items-center mb-[16px]">
          <div className="main-font-bolded text-base leading-5 ml-[24px]">
            {sandbox.name}
          </div>
          <div className="flex flex-row flex-end items-center">
            <div className="text-[12px] text-Grey mr-[24px]">Last saved: {formatDate(sandbox.updatedAt)}</div>
            <div className="green-button mr-[16px] cursor-pointer" onClick={() => viewSandbox(sandbox.id)}> View Sandbox </div>
            <div className="grey-button cursor-pointer" onClick={() => editSandbox(sandbox.id)}>Edit</div>
            <div className="h-[80px] border border-DarkStone mx-[20px]"></div>
            <div className="grey-button mr-[20px] cursor-pointer" 
              onClick={() => {
                setSandboxToDelete(sandbox.id);
                setShowConfirmationRemoveModal(true);
            }}>Remove</div>
          </div>
        </div>
      ))}
      <ConfirmationRemoveModal sandboxId={sandboxToDelete} deleteSandbox={handleDeleteSandbox} resetSandboxId={() => setSandboxToDelete(null)} />
    </div>
  );
}
