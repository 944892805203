import {memo} from "react";

const Tooltip = memo((props) => {
  return (
    <span className={`group relative ${props.class}`}>
      <span className='pointer-events-none absolute -top-[15px] left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-black px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:content-[""] group-hover:opacity-100 z-2' >
        {props.text}
      </span>
      {props.children}
    </span>
  );
});

export default Tooltip;