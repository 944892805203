import DownloadSvg from '../../../../assets/svgs/UploadCSV/DownloadSvg';
import useUploadCSVForm from '../../../hooks/useUploadCSVForm';
import { templates } from '../../../constants/templateLinks';
import {downloadURI} from '../../../utils/utils';

export default function FooterButtons({ setValues }) {
  const { handleSubmit, files, uploadDone, partialUpload, uploading, uploadError } = useUploadCSVForm();

  return <div className='flex-grow max-h-[200px] min-h-[80px] flex flex-col items-center justify-center'>
    <div
      className='flex flex-row xl:gap-[330px] gap-[24px] justify-center items-center'>
      <div className='flex flex-row gap-[24px]'>
        <button
          type='button'
          className='template-button'
          onClick={() => downloadURI(templates.sales)}>
          <DownloadSvg />
          Download sales template
        </button>
        <button
          type='button'
          className='template-button'
          onClick={() => downloadURI(templates.purchase)}
        >
          <DownloadSvg />
          Download purchasing template
        </button>
      </div>
      <button
        onClick={() => handleSubmit(setValues)}
        type='submit'
        className='green-button'
        disabled={files?.length === 0 || uploading || uploadDone || partialUpload || uploadError}
      >
        <span>Upload all files</span>
      </button>
    </div>
  </div>;
}
