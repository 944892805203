import {useContext} from "react";
import {ProductMatchingComponentContext} from "../context/ProductMatchingComponentContext";
import {productMatchingComponents} from "../constants/productMatchingComponents";

export default function useActiveComponent() {
  const {component, setComponent} = useContext(ProductMatchingComponentContext)

  function handleClick(event) {
    setComponent(productMatchingComponents[event.target.id]);
  }
  return { component, handleClick };
}