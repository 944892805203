import SearchMatch from "./SearchMatch";
import Checkmark from "../../../../assets/svgs/ProductMatching/CheckMark";
import useMatching from "../../../hooks/useMatching";
import {setAttributes} from '../../../utils/utils';
import Tooltip from "../../templates/Tooltip";

const {setEntryAttribute, setEntryWeight} = setAttributes;

export default function MatchingEntry({entry}) {
  const {title, weight, category, percentage, carbon_footprint} = entry;
  const {match, confirmed, handleConfirm} = useMatching()

  return <li>
    <div
      className='bg-White rounded-[12px] shadow-[0_5px_18px_rgba(0,0,0,0.1] pl-[24px] pr-[15px] h-[136px] flex relative mr-[5px] '>
      <div
        className='grid grid-cols-7 grid-rows-3 w-full leading-[22px] text-left text-Grey font-normal secondary-font py-[24px]'>
        <p className='text-Black font-[700] col-span-2 truncate leading-[20px] main-font-bolded no-font-synthesis w-full'>
          {setEntryAttribute(title)}
        </p>
        <p className='truncate ml-[5px] w-full'>
          {setEntryWeight(weight)}
        </p>
        <Tooltip text={setEntryAttribute(category)} >
          <p className='truncate ml-[10px] w-full'>
            {setEntryAttribute(category)}
          </p>
        </Tooltip>
        <p className='truncate ml-[15px] w-full'>
          {setEntryAttribute(percentage)}
        </p>
        <p className='truncate ml-[20px] w-full'>
          {setEntryAttribute(carbon_footprint)}
        </p>
        <div className='mr-[24px] row-span-3 col-span-7'>
          <SearchMatch id={entry.id} possibleMatchesMetadata={entry?.possible_matches_metadata} />
        </div>
      </div>
      <div className='row-span-3 col-span-1 flex justify-center items-center gap-[16px] border-l pl-[24px] '>
        <div className='flex justify-center items-center'>
          {!confirmed ?
            <button className='green-button w-[140px]' onClick={() => handleConfirm(entry)} disabled={!match}>
              Confirm match
            </button> : <div className='flex flex-col justify-center items-center text-center gap-[16px] w-[140px]'>
              <Checkmark w='48px' h='48px'/>
              <p className='text-[rgba(16,24,40,1)] font-medium leading-[20px] text-[15px] main-font-bolded'>Match
                confirmed!</p>
            </div>}
        </div>
      </div>
    </div>
  </li>
}