import {useState, createContext} from 'react';

export const SidebarContext = createContext({
  isSidebarMinimized: [], setIsSidebarMinimized: () => {/* this is intentional */
  },
});

export default function SidebarProvider({children}) {
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false)

  return (<SidebarContext.Provider value={{
      isSidebarMinimized, setIsSidebarMinimized,
    }}>
      {children}
    </SidebarContext.Provider>);
}