import * as Yup from 'yup';

export const createProductModalInitialState = {
    name: '',
    weight: '',
    carbonFootprint: '',
    unitPrice: '',
    sku: '',
    manufacturer: '',
    supplier: ''
};

export const contactModalInitialState = {
    name: '',
    telephone: '',
    email: '',
    // productData: ''
}

export const createProductModalValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    weight: Yup.number().required('Weight is required'),
    carbonFootprint: Yup.number().required('Carbon footprint is required'),
    unitPrice: Yup.number().required('Unit price is required'),
    sku: Yup.string(),
    manufacturer: Yup.string(),
    supplier: Yup.string()
});

export const contactModalValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    telephone: Yup.string().required('Telephone is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    // productData: Yup.string().required('Product data is required')
});

export const searchButtons = {
    ingredient: false,
    product: false,
    savedProducts: false,
}