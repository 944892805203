import {useContext} from "react";
import {MatchingEntryContext} from "../context/MatchingEntryContext";
import useProductMatching from "./useProductMatching";


export default function useMatching() {
  const {match, setMatch, confirmed, setConfirmed, isSearching, setIsSearching, displayPossibleMatchesMetadata, setDisplayPossibleMatchesMetadata} = useContext(MatchingEntryContext)
  const {addPendingEntry, confirmMatching, removeMatching} = useProductMatching()

  function toggleSearching(bool) {
    setIsSearching(bool)
  }

  function displayMetaData(bool) {
    setDisplayPossibleMatchesMetadata(bool);
  }

  function handleDropdown(possibleMatch, id) {
    displayPossibleMatchesMetadata && displayMetaData(false);
    setMatch(possibleMatch)
    addPendingEntry(possibleMatch, id)
  }

  function handleConfirm(matchingEntry) {
    setConfirmed(true);
    confirmMatching({
      [matchingEntry.id] :{
        id: matchingEntry.id,
        productTitle: match.title,
      }
    })
  }

  function handleRemove(id) {
    setMatch(null)
    removeMatching(id)
    setConfirmed(false)
  }

  return {match, handleDropdown, confirmed, handleConfirm, handleRemove, isSearching, toggleSearching, setMatch, displayPossibleMatchesMetadata, displayMetaData};
}