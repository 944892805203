export default async function fetchTop100emissions(details) {
      try {
        if (details.fromDate === 0) {
          const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/search-products/${details.pageNumber}&${details.productsNumber}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
          });

        return res.json();
        } else {
          const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/search-products/${details.fromDate}&${details.toDate}&${details.pageNumber}&${details.productsNumber}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
          });

        return res.json();
        }
      } catch (error) {
          console.log(error);
      }
  }