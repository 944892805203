import DynamicSidebar from "../parts/Sidebar/DynamicSidebar";
import SidebarProvider from "../../context/SidebarContext";
import {useLocation} from "react-router-dom";

export default function Sidebar({children}) {
const location = useLocation();

  return (<SidebarProvider>
    {location.pathname !== '/' ? <div className='w-screen h-screen flex overflow-hidden'>
      <DynamicSidebar/>
      <div className='w-full h-full'>
        {children}
      </div>
    </div> : <div className='w-screen h-screen flex justify-start'>
      {children}
    </div>}
  </SidebarProvider>);
}

