import {useEffect, useState} from 'react';
import useProductMatching from './useProductMatching';
import usePagination from './usePagination';
import useActiveComponent from "./useActiveComponent";

export default function useFetchPaginatedEntries(fetchFunction) {
  const [isLoading, setIsLoading] = useState(false);
  const [totalEntries, setTotalEntries] = useState(0);
  const [totalEntriesPerPage, setTotalEntriesPerPage] = useState(0);
  const {handleFetch, reset} = useProductMatching();
  const {paginationState, getPaginationState} = usePagination();
  const {component} = useActiveComponent();

  useEffect(() => {
      reset();
      setIsLoading(true);
      fetchFunction(paginationState?.currentPage).then(res => {
        getPaginationState(res?.meta?.pagination?.pageCount);
        handleFetch(res?.data);
        setTotalEntries(res?.meta?.pagination?.count);
        setTotalEntriesPerPage(res?.data?.length);
        setIsLoading(false);
      })
  }, [paginationState?.currentPage, component]) // eslint-disable-line react-hooks/exhaustive-deps

  return {isLoading, totalEntries, totalEntriesPerPage};
}