export default function CheckSvg({selected}) {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 8.41176L7.77647 14.1765L17.9412 1"
        stroke={selected ? "#0E840B" : "#DBDADA"}
        strokeWidth="3"
      />
    </svg>
  );
}
