import { LayoutSvg, ProfileSvg, CheckSvg, SandBoxSvg } from "../../assets/svgs/Sidebar";
import { secondaryLinks } from "./secondaryLinks";
import { baseUrl } from "./baseUrl";

let sidebarLinks;
if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
  sidebarLinks = {
    [baseUrl.administration]: {
      name: 'Administration',
      href: secondaryLinks.administrationLinks['upload-data'].href,
      icon: <ProfileSvg />,
      subLinks: secondaryLinks.administrationLinks
    },
    [baseUrl.productMatching]: {
      name: 'Product Matching',
      href: baseUrl.productMatching,
      icon: <CheckSvg />
    },
    [baseUrl.sandbox]: {
      name: 'Sandbox',
      href: baseUrl.sandbox,
      icon: <SandBoxSvg />
    },
    [baseUrl.dashboards]: {
      name: 'Dashboards',
      href: secondaryLinks.dashboardLinks['carbon-overview'].href,
      icon: <LayoutSvg />,
      subLinks: secondaryLinks.dashboardLinks
    },
    // [baseUrl.myAccount]: {
    //   name: 'My account', href: baseUrl.myAccount, icon: <RocketSvg/>
    // },
  }
} else {
  sidebarLinks = {
    [baseUrl.administration]: {
      name: 'Administration',
      href: secondaryLinks.administrationLinks['upload-data'].href,
      icon: <ProfileSvg />,
      subLinks: secondaryLinks.administrationLinks
    },
    [baseUrl.sandbox]: {
      name: 'Sandbox',
      href: baseUrl.sandbox,
      icon: <SandBoxSvg />
    },
    [baseUrl.dashboards]: {
      name: 'Dashboards',
      href: secondaryLinks.dashboardLinks['carbon-overview'].href,
      icon: <LayoutSvg />,
      subLinks: secondaryLinks.dashboardLinks
    },
    // [baseUrl.myAccount]: {
    //   name: 'My account', href: baseUrl.myAccount, icon: <RocketSvg/>
    // },
  }
}


export { sidebarLinks };