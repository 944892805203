export default function TickBoxSvg({selected}) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" fill={selected ? '#0E840B' : 'white'} />
      <path d="M13 20.1875L18.6 25L27 14" stroke={selected ? 'white' : "#DBDADA"} strokeWidth="3" />
      <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke={selected ? '#0E840B' : '#585858'} />
    </svg>
  );
}
