import PageBoilerplate from '../templates/PageBoilerplate';
import UploadCSVForm from '../parts/UploadCSVPage/UploadCSVForm';
import UploadCSVFormProvider from "../../context/UploadCSVFormContext";

export default function UploadCSV() {
  return <PageBoilerplate name='Upload Data'>
    <UploadCSVFormProvider>
      <UploadCSVForm/>
    </UploadCSVFormProvider>
  </PageBoilerplate>
}