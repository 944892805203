export default async function saveMatchingProgress(entries) {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/matching-entries/match`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(entries),
    });
    const { data } = await res.json();
    return data;
  } catch (err) {
    console.error(err);
  }
}