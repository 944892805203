import {Arrow, LogOutSvg, Logo} from "../../../../assets/svgs/Sidebar";
import {useLocation, Link} from "react-router-dom";
import {classNames} from "../../../utils/utils";
import {sidebarLinks} from "../../../constants/sidebarLinks";
import useIsLoggedIn from "../../../hooks/useIsLoggedIn";
import useIsValidToken from "../../../hooks/useIsValidToken";
import {useEffect, useState} from "react";
import useSidebar from "../../../hooks/useSidebar";

export default function DynamicSidebar() {
  const {logout} = useIsLoggedIn();
  const {removeTokenValidation} = useIsValidToken();

  const [current, setCurrent] = useState({});
  const [currentSub, setCurrentSub] = useState({});

  const {isSidebarMinimized, toggleMinimize} = useSidebar()

  const location = useLocation();

  useEffect(() => {
    const path = location?.pathname?.split('/');
    sidebarLinks[path[1]] && setCurrent(sidebarLinks[path[1]]);
    (sidebarLinks[path[1]]?.subLinks && path[2] && sidebarLinks[path[1]]?.subLinks[path[2]]) && setCurrentSub(sidebarLinks[path[1]]?.subLinks[path[2]]);
  }, [location]);


  return <div
    className={`border-r bg-Stone flex-1 ${isSidebarMinimized ? 'min-w-[64px]' : 'min-w-[224px]'} h-full flex flex-col duration-500 ease-in-out`}>
    <div className='flex justify-center items-center flex-shrink-0 my-[48.5px] '>
      <Logo/>
    </div>
    <div className='h-0 border border-DarkStone relative w-full'>
      <Arrow
        className={classNames(isSidebarMinimized ? 'rotate-180 left-[50px]' : 'left-[210px]' ,'absolute z-[1] -top-[12px] cursor-pointer duration-500 ')}
        onClick={toggleMinimize}/>
    </div>
    <div className='flex-1 flex flex-col overflow-y-auto overflow-x-hidden  '>
      <nav className={`mt-[24px] flex flex-col flex-1 ${isSidebarMinimized ? 'mx-[12px]' : 'mx-[16px]'} gap-[8px]`}>
        {Object.values(sidebarLinks)?.map((value) => (<div
          key={value.name}
        >
          <Link
            to={value.href}
            className={classNames(current === value ? ' main-font-bolded bg-Yellow text-Black rounded-[4px]' : 'text-Black main-font hover:main-font-bolded hover:tracking-normal hover:rounded-[4px] tracking-[1.2px] hover:bg-DarkStone', isSidebarMinimized ? 'justify-center w-[40px]' : 'px-[16px]', 'w-full h-[40px] flex flex-row items-center gap-[8px] text-[15px] leading-[20px] py-[10px] no-font-synthesis',)}
            onClick={() => {
              setCurrent(value);
            }}
          >
            {value.icon}
            {!isSidebarMinimized && <span className='w-full h-[20px] overflow-hidden mt-[2px]'>
                {value.name}
                </span>}
          </Link>
          {current === value && value?.subLinks && !isSidebarMinimized &&
            <div className='flex flex-col mt-[12px] ml-[17px] mb-[16px]'>
              {Object.values(value.subLinks).map(subLink => <Link
                key={subLink.name}
                to={subLink.href}
                className={classNames(currentSub === subLink ? 'text-Black secondary-font-bolded' : 'text-Grey font-medium', 'mb-[16px] last:mb-0 text-[15px] leading-[18px] hover:secondary-font-bolded hover:text-Black secondary-font no-font-synthesis ')}
                onClick={() => {
                  setCurrentSub(subLink);
                }}
              >
                {subLink.name}
              </Link>)}
            </div>}
        </div>))}
      </nav>
    </div>
    <div
      className={`${isSidebarMinimized ? 'opacity-0' : 'opacity-100 mb-[10px]'} sidebar-photo:hidden transition-opacity duration-200`}>
      <img src='/imgs/Zedible_Branding_Happy_Planet.png' alt=''/>
    </div>
    <button
      className='w-full flex mb-8 px-[16px] justify-center overflow-x-hidden'
      onClick={() => {
        removeTokenValidation();
        logout();
      }}
    >
      <div
        className={`${isSidebarMinimized ? 'px-[12px]  ' : 'w-full px-[16px]'} h-[40px] flex flex-row items-center gap-[8px]  py-[10px]  hover:rounded-[4px] hover:bg-DarkStone hover:main-font-bolded text-Black text-[15px] leading-[20px] main-font`}>
        <LogOutSvg/>
        {!isSidebarMinimized && <p className='h-[20px] overflow-x-hidden mt-[2px]'>Log out</p>}
      </div>
    </button>
  </div>
}