import { createContext, useState } from 'react';

export const PaginationContext = createContext({
  paginationState: {
    currentPage: 1,
    totalPages: 1,
  },
  setPaginationState: () => {/* this is intentional */
  },
});

export default function PaginationProvider({ children }) {
  const [paginationState, setPaginationState] = useState({
    currentPage: 1,
    totalPages: 1,
  });

  return (
    <PaginationContext.Provider value={{
      paginationState,
      setPaginationState,
    }}>
      {children}
    </PaginationContext.Provider>
  );
}