import useProductMatching from "./useProductMatching";
import {WeightConfirmContext} from "../context/WeightConfirmContext";
import {useContext} from "react";

export default function useWeightConfirm() {
  const {match, setMatch, isConfirmed, setIsConfirmed} = useContext(WeightConfirmContext)
  const {confirmMatching, removeMatching} = useProductMatching()

  function checkConfirmation(confirmed) {
    setIsConfirmed(confirmed)
  }

  function handleClick(weight, quantity, unitWeight) {
    setMatch({weight, quantity, unitWeight})
  }

  function handleWeightConfirm(matchingEntry) {
    setIsConfirmed(true)
    confirmMatching({
      [matchingEntry.id]: {
        id: matchingEntry.id,
        weight: match.weight,
        qty: match.quantity,
        displayed_weight: match.unitWeight
      }
    })
  }

  function handleRemove(id) {
    setIsConfirmed(false)
    removeMatching(id)
  }

  return {match, isConfirmed, handleClick, handleWeightConfirm, handleRemove, checkConfirmation};
}