import {useContext} from "react";
import {SidebarContext} from "../context/SidebarContext";

export default function useSidebar() {
  const {isSidebarMinimized, setIsSidebarMinimized} = useContext(SidebarContext)

  function toggleMinimize() {
    setIsSidebarMinimized(!isSidebarMinimized);
  }

  return {isSidebarMinimized, toggleMinimize};
}