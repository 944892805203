import React from 'react'
import TickBoxSvg from '../../../../../assets/svgs/Sandbox/TickBoxSvg'

export default function SearchOptions({stateButtons, setActiveButton}) {
  return (
    <div className="flex flex-row justify-between mb-[24px] max-w-[500px] ml-[64px]">
            <div className="flex flex-row justify-between items-center">
              <div
                className="cursor-pointer"
                onClick={() => setActiveButton("ingredient")}>
                <TickBoxSvg
                  selected={stateButtons.ingredient > 0 ? true : false}
                />
              </div>
              <div className="ml-[16px] main-font-bolded font-[15px]">
                Ingredient
              </div>
            </div>
            <div className="flex flex-row justify-between items-center">
              <div
                className="cursor-pointer"
                onClick={() => setActiveButton("product")}>
                <TickBoxSvg
                  selected={stateButtons.product > 0 ? true : false}
                />
              </div>
              <div className="ml-[16px] main-font-bolded font-[15px]">
                Product
              </div>
            </div>
            <div className="flex flex-row justify-between items-center">
              <div
                className="cursor-pointer"
                onClick={() => setActiveButton("savedProduct")}>
                <TickBoxSvg
                  selected={stateButtons.savedProduct > 0 ? true : false}
                />
              </div>
              <div className="ml-[16px] main-font-bolded font-[15px]">
                Saved products
              </div>
            </div>
          </div>
  )
}
