export const sandboxDetails = {
    currentPage: 0,
    totalPages: 1,
    hideButtons: false,
    searchTitle: "",
    products: [],
    sendingRequest: false,
    showFindProduct: true,
    totalPercentage: 0,
  }

export const pagesObject = {
  currentPage: 0,
  totalPages: 1,
}

export const marginDetails = {
  dataRange: null,
  revenue: "",
  costOfSale: "",
  totalCo2: "",
  query: "",
}

