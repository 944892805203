/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { SandboxContext } from "../../../../context/SandboxContext";
import { returnIsValidPercentageInput } from "../../../../utils/utils"; 
import LockSvg from "../../../../../assets/svgs/Sandbox/LockSvg";

export default function AdjustUnits({existingProduct}) {
  const { showAdjustModal, setShowAdjustModal, sandbox, setSandbox, sandboxScenario, setSandboxScenario, compareProducts, setCompareProducts } = useContext(SandboxContext);
  const [percentage, setPercentage] = useState(null);

  useEffect(() => {
    if (showAdjustModal) {
      setPercentage(sandbox.toSandboxPercentage);
    }
  }, [showAdjustModal]);

  const handlePercentageChange = (index, value) => {
    const checkedInput = returnIsValidPercentageInput(value);

    if (!checkedInput) return;

    setPercentage(value);
  }

  const handleSaveChangesButton = () => {
    setSandbox({
      ...sandbox,
      toSandboxPercentage: percentage,
    });

    const newCompareProducts = compareProducts.map((product, index) => {
      product.weight = (existingProduct.weight * (product.percentage / 100)).toFixed(2);
      product.totalCo2 = (product.weight * product.co2perkg).toFixed(2);

      return product;
    });

    setSandboxScenario({
      ...sandboxScenario,
      weight: (sandbox.weight * (percentage / 100)).toFixed(2)
    });

    setCompareProducts(newCompareProducts);
    setShowAdjustModal(false);
  }

  return (
    <Transition.Root show={showAdjustModal} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10 top-2/4 max-w-[440px]"
              onClose={() => {
                setShowAdjustModal(false);
              }}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full md:items-end justify-center p-4 text-center items-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 scale-95 md:scale-0">
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-[440px] my-auto">
                      <div>
                        <div className="text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-2xl text-center font-bold text-black main-font-bolded my-[40px]">
                            Adjust the percentage
                          </Dialog.Title>
                        </div>
                      </div>
                      <div className="flex justify-between flex-wrap mx-[40px] mb-[16px]">
                        <div className="flex justify-between items-center max-w-[228px] w-full h-[56px] border border-gray-300 rounded-md">
                          <div className="main-font-bolded text-[16px] ml-[16px]">{sandbox.name}</div>
                          <div className="mr-[21px]"><LockSvg /></div>
                        </div>
                        <div className="relative">
                          <input
                            type="text"
                            name="sandboxPercentage"
                            id="sandboxPercentage"
                            className="block text-[16px] secondary-font w-full max-w-[116px] h-[56px] flex-1 rounded-md border-0 py-1.5 text-black ring-1 ring-inset ring-gray-300 placeholder:text-black focus:ring-1 focus:ring-inset focus:ring-Grey sm:text-sm sm:leading-6"
                            value={percentage}
                            onChange={(e) => handlePercentageChange(0, e.target.value)}
                            />
                            <span className="absolute right-[19px] top-[17px] text-[17px] secondary-font">%</span>
                            <div className="absolute right-[54px] top-[4px] text-[17px] h-[48px] w-[1px] bg-gray-300"></div>
                        </div>
                      </div>
                      <div className="flex justify-center mb-[40px]">
                        <div className="green-button cursor-pointer w-full max-w-[145px]" onClick={() => handleSaveChangesButton()}>Save changes</div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
  )
}
