import { useState, createContext } from 'react';

export const UserContext = createContext({
  isLoggedIn: false,
  setIsLoggedIn: () => {/*this is intentional*/},
  isValidToken: false,
  setIsValidToken: () => {/*this is intentional*/},
});

export default function UserProvider({ children }) {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isValidToken, setIsValidToken] = useState(false);

  return (
    <UserContext.Provider value={{ isLoggedIn, setIsLoggedIn, isValidToken, setIsValidToken }}>
      {children}
    </UserContext.Provider>
  );
}
