export default async function fetchContactRequest(data) {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/sandbox-products/contact-support`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(data),
      });
      return res.json();
    } catch (err) {
      console.error(err);
    }
  }