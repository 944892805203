import React from "react";
import { analyticsClickedButton } from "../../../../utils/utils";

export default function BannerChooseProduct({ addProductToSelectedProducts, checkIfSearchProductSelected }) {
  return (
    <>
      {checkIfSearchProductSelected() && (
        <div className="absolute bottom-0 w-full bg-[#FBFBF8]">
          <div className="left-0 w-full h-0 border border-DarkStone"></div>
            <div className="flex flex-row justify-center my-[36px]">
              <div
                className="green-button mr-[16px] cursor-pointer"
                onClick={() => {
                  analyticsClickedButton('Searched substitution', 'Choose product');
                  addProductToSelectedProducts();
                  }}>
                Choose product
              </div>
            </div>
        </div>
      )}
    </>
  );
}
