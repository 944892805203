export default async function fetchMatchingEntriesWithSuggestions(page=1, pageSize=32) {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/matching-entries/suggestions/${page}&${pageSize}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return res.json()
  }
  catch(err) {
    console.error(err)
  }
}
