import useUploadCSVForm from "../../../hooks/useUploadCSVForm";
import {classNames} from "../../../utils/utils";
import { useEffect, useRef } from 'react';
import Lottie from 'lottie-react';
import {acceptedFileFormats} from "../../../constants/acceptedFileFormats";
import uploadSpinner from '../../../../assets/animations/uploadSpinner.json';

export default function Dropzone() {
  const {handleDrag, handleDrop, uploading, handleInputLabelClick, handleInputFile, uploadDone,uploadError} = useUploadCSVForm();
  const inputElement = useRef(null)
  const lottieRef = useRef(null);

  useEffect(() => {
    if (uploading === true) {
      lottieRef.current.setSpeed(1.5);
      lottieRef.current.goToAndPlay(0, true);
    }
  }, [uploading]);

  useEffect(() => {
    if (uploadError === true) {
      lottieRef.current.pause();
    }
  }, [uploadError]);

  function handleClick(){
    handleInputLabelClick()
    inputElement.current.click()
  }

  return <div
    className='flex rounded-[12px] bg-white xl:w-[440px] w-[45%] h-[536px] shadow-[0_5px_18px_rgba(0,0,0,0.1)] p-[16px] z-2'
    onDragOver={handleDrag}
    onDragLeave={handleDrag}
    onDrop={handleDrop}
  >
    <div
      id='dropzone'
      className='flex justify-center border border-DarkStone border-dashed bg-White rounded w-full h-full '>
      <div className='flex flex-col justify-center items-center text-center mt-[84px]'>
        <div
          className='w-full text-[15px] font-medium leading-[24px] text-Grey uppercase mt-[16px] mb-[16px] secondary-font'>
          <p>Drag and drop files here or</p>
        </div>
        <button
          type='button'
          className={classNames(
            uploading
              ? 'cursor-default opacity-50'
              : 'cursor-pointer',
            'border-Yellow text-Black bg-Yellow inline-flex justify-center py-2 px-4 border shadow-[0_1px_2px_rgba(16,24,40,0.05)] text-[15px] main-font-bolded rounded-[8px] leading-[20px] z-1 mb-[25px]',
          )}
          onClick={handleClick}
        >
          Select files
          <input
            ref={inputElement}
            name='file-upload'
            type='file'
            className='sr-only'
            multiple
            onChange={handleInputFile}
            accept={acceptedFileFormats.types.join(', ')}
            value=''
            disabled={uploading || uploadDone}
          />
        </button>
        <Lottie lottieRef={lottieRef} animationData={uploadSpinner} loop={false} autoplay={false} style={{
          width: 300,
          height: 300,
        }} className='z-0 scale-[1.4] mb-[23px] overflow-hidden'/>
      </div>
    </div>
  </div>
}