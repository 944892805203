import {useContext} from 'react';
import {nanoid} from 'nanoid';
import {filterByName} from '../utils/utils';
import uploadCSV from '../utils/uploadCSV';
import {UploadCSVFormContext} from '../context/UploadCSVFormContext';
import {acceptedFileFormats} from '../constants/acceptedFileFormats';

export default function useUploadCSVForm() {

  const {
    files,
    setFiles,
    dataType,
    setDataType,
    wrongExtensionFiles,
    setWrongExtensionFiles,
    wrongTypeFiles,
    setWrongTypeFiles,
    largeFiles,
    setLargeFiles,
    duplicateFiles,
    setDuplicateFiles,
    brokenHeadersFiles,
    setBrokenHeadersFiles,
    emptyFiles,
    setEmptyFiles,
    exceededRowsFiles,
    setExceededRowsFiles,
    badDateFiles,
    setBadDateFiles,
    missingDataFiles,
    setMissingDataFiles,
    dragActive,
    setDragActive,
    uploading,
    setUploading,
    partialUpload,
    setPartialUpload,
    uploadDone,
    setUploadDone,
    uploadError,
    setUploadError,
    isDropdownActive,
    setIsDropdownActive,
  } = useContext(UploadCSVFormContext);

  function filesValidations(file) {
    const oldFileNames = files.map((oldFile) => oldFile.name);
    if (!acceptedFileFormats.types.includes(file.type)) {
      // array of valid file types
      return setWrongExtensionFiles((prevWrongExtensionFiles) => [...prevWrongExtensionFiles, file]);
    } else if (!acceptedFileFormats.extensions.includes(file.name.match(/.[a-z]{3,4}$/)[0])) {
      return setWrongExtensionFiles((prevWrongExtensionFiles) => [...prevWrongExtensionFiles, file]);
    } else if (file.size / 1024 > 5119) {
      return setLargeFiles((prevBigFiles) => [...prevBigFiles, file]);
    } else if (oldFileNames.includes(file.name)) {
      return setDuplicateFiles((prevDuplicateFiles) => [...prevDuplicateFiles, file]);
    } else return file;
  }

  function handleInputFile(event, button = true) {
    button && event.preventDefault();
    const arrayOfFiles = Array.from(button ? event.target.files : event.dataTransfer.files).filter(
      (file) => filesValidations(file),
    );

    if (arrayOfFiles.length > 1) {
      arrayOfFiles.map((file) => (file.id = nanoid()));
      setFiles(() => [...files, ...arrayOfFiles]);
    } else if (arrayOfFiles.length === 1) {
      const file = arrayOfFiles[0];
      file.id = nanoid();
      setFiles(() => [...files, file]);
    }
  }

  function selectOption(type) {
    setDataType(type);
  }

  function handleInputLabelClick() {
    resetValidations();
    if (partialUpload || uploadError) {
      resetValidations(true);
      resetState();
    }
  }

  function resetValidations(fullReset = false) {
    setWrongExtensionFiles([]);
    setLargeFiles([]);
    setDuplicateFiles([]);
    if (fullReset) {
      setBrokenHeadersFiles([]);
      setEmptyFiles([]);
      setWrongTypeFiles([]);
      setExceededRowsFiles([]);
      setBadDateFiles([]);
      setMissingDataFiles([]);
    }
  }

  function toggleDropdown() {
    if (files?.length < 1 || partialUpload || uploadError) {
      setIsDropdownActive(!isDropdownActive);
      if (partialUpload || uploadError) {
        resetValidations(true);
        resetState();
      }
    }
  }

  function closeDropdown() {
    setIsDropdownActive(false);
  }

  function handleDrag(e) {
    if (uploading === false && uploadDone === false) {
      e.preventDefault();
      e.stopPropagation();
      closeDropdown();

      resetValidations();
      if (partialUpload || uploadError) {
        resetValidations(true);
        resetState();
      }

      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragActive(true);
      } else if (!e.relatedTarget && e.type === 'dragleave') {
        // added the first condition to prevent the dragleave event from firing when the user enters the main dropzone
        setDragActive(false);
      }
    }
  }

  function handleDrop(event) {
    if (uploading === false && uploadDone === false) {
      event.preventDefault();
      event.stopPropagation();
      closeDropdown();

      resetValidations();
      if (partialUpload || uploadError) {
        resetValidations(true);
        resetState();
      }

      setDragActive(false);

      if (event.dataTransfer.files && event.dataTransfer.files[0]) {
        handleInputFile(event, false);
      }
    }
  }

  function handleRemove(name) {
    setFiles(() => files.filter((file) => file.name !== name));
    resetValidations();
    filterByName(setBrokenHeadersFiles, brokenHeadersFiles, name);
    filterByName(setEmptyFiles, emptyFiles, name);
    filterByName(setWrongTypeFiles, wrongTypeFiles, name);
    filterByName(setExceededRowsFiles, exceededRowsFiles, name);
    filterByName(setBadDateFiles, badDateFiles, name, true);
    filterByName(setMissingDataFiles, missingDataFiles, name, true);
  }

  function handleSubmit(setValues) {
    setValues(() => ({formDataType: dataType, formFiles: [...files]}), true);
    resetValidations(true);
  }

  function addErrorFiles(brokenHeaders, empty, wrongType, exceededRows, badDates, missingData) {
    brokenHeaders?.length && setBrokenHeadersFiles(brokenHeaders);
    empty?.length && setEmptyFiles(empty);
    wrongType?.length && setWrongTypeFiles(wrongType);
    exceededRows?.length && setExceededRowsFiles(exceededRows);
    badDates?.length && setBadDateFiles(badDates);
    missingData?.length && setMissingDataFiles(missingData);
  }

  function handleUpload(formDataType, formFiles, setErrors, resetForm) {
    uploadCSV(formDataType, formFiles).then(async (res) => {
      if (res.status === 400) {
        setUploading(false);
        setErrors({formFiles: 'Something went wrong, please try again'});
        resetForm();
        return;
      }
      const {
        success,
        brokenHeaders,
        empty,
        wrongType,
        exceededRows,
        badDates,
        missingData
      } = await res.json().then(data => data);
      const allErrors = [...brokenHeaders, ...empty, ...wrongType, ...exceededRows, ...badDates, ...missingData];
      setUploading(true);

      if (success?.length === formFiles?.length) {
        setErrors({});
        resetForm();
      } else if (allErrors?.length === formFiles?.length) {
        setUploading(false);
        setUploadError(true);
        addErrorFiles(brokenHeaders, empty, wrongType, exceededRows, badDates, missingData);
        resetForm();
      } else if (allErrors?.length && success?.length) {
        setPartialUpload(true);
        addErrorFiles(brokenHeaders, empty, wrongType, exceededRows, badDates, missingData);
        resetForm();
      }

      if (!success?.length && !allErrors?.length) {
        setUploading(false);
        setErrors({formFiles: 'Something went wrong, please try again'});
        resetForm();
      }
    });
  }

  function uploadFinish() {
    setUploading(false);
    if (brokenHeadersFiles?.length > 0 || emptyFiles?.length > 0 || wrongTypeFiles?.length > 0 || exceededRowsFiles?.length > 0 || badDateFiles?.length > 0 || missingDataFiles?.length > 0) {
      setPartialUpload(true);
    } else {
      setUploadDone(true);
    }
  }

  function resetState(fullReset = false) {
    setFiles([]);
    setUploading(false);
    setUploadDone(false);
    setPartialUpload(false);
    setUploadError(false);
    if (fullReset) {
      setDataType('Purchasing Data');
      resetValidations(true);
    }
  }

  return {
    files,
    dataType,
    wrongExtensionFiles,
    largeFiles,
    duplicateFiles,
    dragActive,
    brokenHeadersFiles,
    wrongTypeFiles,
    emptyFiles,
    exceededRowsFiles,
    badDateFiles,
    missingDataFiles,
    uploading,
    partialUpload,
    uploadDone,
    handleInputFile,
    selectOption,
    handleDrag,
    handleDrop,
    handleRemove,
    resetState,
    setUploading,
    handleSubmit,
    handleInputLabelClick,
    uploadFinish,
    handleUpload,
    isDropdownActive,
    toggleDropdown,
    closeDropdown,
    uploadError,
  };
}
