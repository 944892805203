import MatchingHeaders from "./MatchingHeaders";
import MatchesList from "./MatchesList";
import Spinner from '../../templates/Spinner';
import Pagination from "../../templates/Pagination";
import useProductMatching from "../../../hooks/useProductMatching";

export default function ManualMatch({isLoading}) {
  const {confirmedEntries} = useProductMatching();

  return <div className='h-full flex flex-col mx-[64px] mt-[16px]'>
    <MatchingHeaders/>
    {isLoading ? <Spinner what='Abstract Matching'/> : <MatchesList/>}
    <Pagination modalTrigger={!!Object.keys(confirmedEntries)?.length} />
  </div>
}
