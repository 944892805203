export default async function fetchSavedSandboxes() {
      try {
          const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/sandbox-scenarios/`, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              }
            });
  
          return res.json();
      } catch (error) {
          console.log(error);
      }
  }