import { useCallback } from 'react';
import MatchingEntryProvider from '../../../context/MatchingEntryContext';
import MatchingEntry from './MatchingEntry';
import useProductMatching from '../../../hooks/useProductMatching';

export default function MatchesList() {
  const { entriesToBeConfirmed } = useProductMatching();

  const renderEntries = useCallback(() => {
    if (entriesToBeConfirmed?.length === 0) {
      return <p
        className=' flex justify-center items-center w-full h-full rounded-[8px] font-[14px] leading-[20px] py-[8px] pl-[8px] text-Black font-normal main-font-bolded'>No
        entries to match...</p>
    }
    return entriesToBeConfirmed?.map(entry => <MatchingEntryProvider key={entry.id}>
      <MatchingEntry entry={entry} />
    </MatchingEntryProvider>);
  }, [entriesToBeConfirmed]);

  return <ul className='w-full min-h-[calc(100%_-_315px)] max-h-[calc(100%_-_315px)] flex flex-col gap-[24px] list-scrollbar'>
    {renderEntries()}
  </ul>;
}