import {useEffect, useRef, useState} from 'react';
import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import SearchDropdown from "./SearchDropdown";
import useMatching from "../../../hooks/useMatching";
import Match from "./Match";
import {classNames} from "../../../utils/utils";
import findMatch from "../../../utils/findMatch";
import useActiveComponent from '../../../hooks/useActiveComponent';
import {productMatchingComponents} from '../../../constants/productMatchingComponents';
import useProductMatching from '../../../hooks/useProductMatching';
import useOnClickOutside from "../../../hooks/useOnClickOutside";

const searchSchema = Yup.object().shape({
  searchQuery: Yup.string('Input must be a string').required('Input is required'),
});

export default function SearchMatch({id, possibleMatchesMetadata}) {
  const [possibleMatches, setPossibleMatches] = useState([]);
  const {match, isSearching, toggleSearching, setMatch, displayPossibleMatchesMetadata, displayMetaData} = useMatching();
  const {addPendingEntry} = useProductMatching();
  const {component} = useActiveComponent();
  const inputRef = useRef();
  const condition = component.id === productMatchingComponents.percentageMatch.id;

  useOnClickOutside(inputRef, () => {
    toggleSearching(false);
    displayMetaData(false);
  });

  useEffect(() => {
    setMatch(possibleMatchesMetadata[0])
    addPendingEntry(possibleMatchesMetadata[0], id)
  }, [condition]) // eslint-disable-line react-hooks/exhaustive-deps

  function search(query) {
    findMatch(query.trim()).then(res => {
      if (!res?.error) {
        setPossibleMatches(res)
      } else {
        setPossibleMatches([])
      }
    })
  }

  return (<>
      <div ref={inputRef}
           className={classNames(match ? 'shadow-[inset_2px_2px_4px_rgba(0,0,0,0.1)]' : '', 'w-full h-[56px] bg-White border-Lightgrey border rounded-[8px] flex items-center')}>
        {!match ? <Formik
            initialValues={{
              searchQuery: '',
            }}
            validationSchema={searchSchema}
            onSubmit={(values) => {
              search(values.searchQuery)
              displayMetaData(false);
              toggleSearching(true)
            }}>
            {({resetForm, handleChange, handleBlur}) => (<Form className='h-full w-full relative'>
              <div className='w-full h-full flex flex-row justify-between p-[8px] gap-[8px] items-center h-full'>
                <Field
                  id={id}
                  name='searchQuery'
                  className='placeholder-Grey text-Black font-normal leading-[22px] w-full flex items-center h-full appearance-none p-[16px] remove-focus secondary-font rounded'
                  placeholder='Search for a match here'
                  onKeyDown={(event) => {
                    if (event.key === 'Escape') {
                      resetForm();
                      toggleSearching(false);
                      setPossibleMatches([]);
                      displayMetaData(false);
                    }
                  }}
                  onChange={(event) => {
                    handleChange(event)
                    isSearching && toggleSearching(false);
                    if (event.target.value.length < 1) {
                      resetForm();
                      setPossibleMatches([]);
                      toggleSearching(false);
                    }
                  }}
                  onBlur={handleBlur}
                />
                <div className='flex items-center justify-center'>
                  {!isSearching ? <div className='flex gap-[15px]'>
                      {possibleMatchesMetadata?.length > 0 && <button type='button'  onClick={() => {
                        displayMetaData(true)
                      }} className='green-button whitespace-nowrap'>
                        Get all suggestions
                      </button>}
                      <button type='submit' className='yellow-button'>
                        Search
                      </button>
                    </div>
                    : <button
                      type='button'
                      onClick={() => {
                        resetForm();
                        setPossibleMatches([]);
                        toggleSearching(false);
                        displayMetaData(false);
                      }}
                      className='text-Grey hover:text-Error text-[15px] leading-[20px] font-medium cursor-pointer disabled:opacity-50 mr-[24px] secondary-font'
                    >
                      Clear
                    </button>}
                </div>
              </div>
              {(isSearching || displayPossibleMatchesMetadata) && <SearchDropdown id={id} options={isSearching ? possibleMatches : possibleMatchesMetadata}/>}
            </Form>)}
          </Formik>
          : <Match id={id}/>}
      </div>
    </>
  )
}