import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../components/pages/Login';
import CustomerSpaceRoutes from './CustomerSpaceRoutes';
import {sidebarLinks} from "../constants/sidebarLinks";
import useIsLoggedIn from "../hooks/useIsLoggedIn";

export default function AppRoutes() {
  const {isLoggedIn} = useIsLoggedIn()
  return <Routes>
  <Route path='/' element={isLoggedIn ? <Navigate to={sidebarLinks.administration.href} replace /> : <Login />} />
  <Route path='/*' element={isLoggedIn ? <CustomerSpaceRoutes /> : <Navigate to='/' replace />} />
</Routes>
}