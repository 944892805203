import Checkmark from "../../../../assets/svgs/ProductMatching/CheckMark";
import useMatching from "../../../hooks/useMatching";
import useProductMatching from "../../../hooks/useProductMatching";
import {setAttributes} from '../../../utils/utils';
const {setEntryAttribute, setEntryCarbonFootprint} = setAttributes;

export default function Match({id}) {

  const {match, handleRemove, toggleSearching} = useMatching()
  const {removePendingEntry} = useProductMatching()

  function removeMatch() {
    toggleSearching(false)
    handleRemove(id)
    removePendingEntry(id)
  }

  return <>
    <div className='grid grid-cols-7 w-full leading-[20px] text-left px-[16px] py-[12px] items-center text-Black secondary-font'>
      <div className='col-span-2 flex items-center gap-[12px] w-full'>
        <Checkmark/>
        <p className='text-Black truncate main-font-bolded py-[1px] w-full'>
          {setEntryAttribute(match?.title)}
        </p>
      </div>
      <p className='truncate ml-[25px] py-[1px] w-full'>
        1000g
      </p>
      <p className={!match?.category_product ? 'text-Grey truncate ml-[48px] w-full' : 'truncate ml-[48px] py-[1px] w-full'} >
        {setEntryAttribute(match?.category_product)}
      </p>
      <p className={!match?.percentage ? 'text-Grey truncate ml-[70px] w-full' : 'truncate ml-[70px] py-[1px] w-full'}>
        {setEntryAttribute(match?.percentage)}
      </p>
      <p className={!match?.carbon_footprint ? 'text-Grey truncate ml-[93px] w-full' : 'truncate ml-[93px] py-[1px] w-full'}>
        {setEntryCarbonFootprint(match?.carbon_footprint)}
      </p>
    </div>
    <button
      type='button'
      onClick={removeMatch}
      className='text-Grey hover:text-Error text-[15px] leading-[20px] font-medium cursor-pointer disabled:opacity-50 mr-[32px] secondary-font'
    >
      Clear
    </button>
  </>
}