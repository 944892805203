export default async function fetchLogin(email, password) {
  try {
    const body = {
      identifier: email,
      password,
    };
    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/auth/local`, { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    return await res.json();
  } catch (error) {
    return error;
  }
}
