import useActiveComponent from '../../../hooks/useActiveComponent';
import { productMatchingComponents } from '../../../constants/productMatchingComponents';
import useProductMatching from '../../../hooks/useProductMatching';
import {classNames} from '../../../utils/utils';

export default function BeforeMain({totalCount, totalCountPerPage}) {
  const { component } = useActiveComponent();
  const { entriesToBeConfirmed, pendingEntries, confirmedEntries } = useProductMatching();

  function checkConfidenceLevel() {
    if (component.id === productMatchingComponents.percentageMatch.id) {
      return pendingEntries?.some(pending => pending?.entry?.percentage?.match(/\d+/) > 30);
    } else return 0;
  }

  function getConfidentMatches() {
    if (component.id === productMatchingComponents.percentageMatch.id) {
      return pendingEntries?.filter(pending => pending?.entry?.percentage?.match(/\d+/) > 30).length;
    } else return 0;
  }

  return <div className='flex justify-between mx-[64px] mt-[25px] gap-[5px]'>
    <div className='max-w-[650px]'>
      <p className='font-normal leading-[22px] text-Grey secondary-font'>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit nullam non eleifend, neque cras tempor id felis et
        efficitur nunc ultricies leo lectus quis.
      </p>
    </div>
    <div className='flex flex-col gap-[10px]'>
      {component.id !== productMatchingComponents.weightMatch.id && <div
        className={classNames(checkConfidenceLevel() ? 'bg-[rgba(127,201,160,0.2)]' : 'bg-[rgba(255,111,111,0.2)]', 'flex justify-center items-center rounded-[8px] mix-blend-multiply py-[5px] px-[12px]')}>
        <p
          className={classNames(checkConfidenceLevel() ? 'text-Promote' : 'text-Error', ' font-medium text-[15px] leading-[20px] text-center secondary-font')}>
          {!!entriesToBeConfirmed?.length && checkConfidenceLevel() ? `${totalCount - totalCountPerPage + getConfidentMatches()} matches found above 30% confidence` : 'No matches found above 30% confidence'}
        </p>
      </div>}
      <p
        className='text-Grey font-medium text-[15px] leading-[20px] tracking-tighter uppercase text-right secondary-font'>
        {`${entriesToBeConfirmed?.length ? (totalCount - totalCountPerPage + entriesToBeConfirmed?.length - Object.keys(confirmedEntries)?.length) || '0' : '...'} of ${entriesToBeConfirmed?.length ? totalCount - totalCountPerPage + entriesToBeConfirmed?.length || '0' : '...'} ${component.id !== productMatchingComponents.weightMatch.id ? 'products to match' : 'weights to confirm'}`}
      </p>
    </div>
  </div>;
}