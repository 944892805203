import { useContext } from 'react';
import { ProductMatchingContext } from '../context/ProductMatchingContext';
import saveMatchingProgress from '../utils/saveMatchingProgress';
import useActiveComponent from './useActiveComponent';
import saveConfirmingProgress from '../utils/saveConfirmingProgress';
import { productMatchingComponents } from '../constants/productMatchingComponents';

export default function useProductMatching() {
  const {
    entriesToBeConfirmed,
    setEntriesToBeConfirmed,
    pendingEntries,
    setPendingEntries,
    confirmedEntries,
    setConfirmedEntries,
  } = useContext(ProductMatchingContext);
  const { component } = useActiveComponent();

  function handleFetch(res) {
    setEntriesToBeConfirmed(res);
  }

  function addPendingEntry(entry, id) {
    setPendingEntries((prev) => [...prev, { id, entry }]);
  }

  function removePendingEntry(id) {
    setPendingEntries(() => pendingEntries.filter(pending => pending?.id !== id));
  }

  function confirmMatching(matching) {
    setConfirmedEntries(() => Object.assign({}, confirmedEntries, matching));
  }

  function removeMatching(id) {
    setConfirmedEntries(() => {
      delete confirmedEntries[id];
      return Object.assign({}, confirmedEntries);
    });
  }

  function save() {
    if (component.id === productMatchingComponents.weightMatch.id) {
      saveConfirmingProgress(confirmedEntries);
    } else {
      saveMatchingProgress(confirmedEntries);
    }
    setEntriesToBeConfirmed(() => entriesToBeConfirmed.filter((entry) => confirmedEntries[entry.id] === undefined));
    setPendingEntries(() => pendingEntries.filter((entry) => confirmedEntries[entry.id] === undefined));
    setConfirmedEntries({});
  }

  function reset() {
    setEntriesToBeConfirmed([]);
    setPendingEntries([]);
    setConfirmedEntries({});
  }

  return {
    entriesToBeConfirmed,
    handleFetch,
    pendingEntries,
    addPendingEntry,
    removePendingEntry,
    confirmedEntries,
    confirmMatching,
    removeMatching,
    save,
    reset,
  };
}