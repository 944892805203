import { createContext, useState } from "react";
import { marginDetails, pagesObject } from "../constants/sandbox";
import { createProductModalInitialState } from "../constants/modals";


export const SandboxContext = createContext({
    selectedProducts: [], // will keep initial selected products on select-product page
    setSelectedProducts: (products) => {},
    editedProducts: [],  // will keep edited products by percentage on search-product page
    setEditedProducts: (products) => {},
    sandbox: {},
    setSandbox: (sandbox) => {},
    showRequestHelpModal: false,
    setShowRequestHelpModal: (show) => {},
    showCreateModal: false,
    setShowCreateModal: (show) => {},
    showContactModal: false,
    setShowContactModal: (showContactModal) => {},
    showCostOfSaleModal: false,
    setShowCostOfSaleModal: (showCostOfSaleModal) => {},
    compareProducts: [],
    showAdjustModal: false,
    setShowAdjustModal: (showAdjustModal) => {},
    setCompareProducts: (products) => {},
    sandboxScenario: {},
    setSandboxScenario: (sandboxScenario) => {},
    marginImpactDetails: marginDetails,
    createProductData: {},
    setCreateProductData: (createProductData) => {},
    searchedProducts: [],
    setSearchedProducts: (products) => {},
    showCompareProductsScreen: false,
    setShowCompareProductsScreen: (showCompareProductsScreen) => {},
    emissionPages: pagesObject,
    setEmissionPages: (emissionPages) => {},
    showConfirmationRemoveModal: false,
    setShowConfirmationRemoveModal: (showConfirmationRemoveModal) => {},
    showOverwriteModal: false,
    setShowOverwriteModal: (showOverwriteModal) => {},
})

export default function SandboxContextProvider({ children }) {
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [editedProducts, setEditedProducts] = useState([]);
    const [sandbox, setSandbox] = useState({});
    const [showRequestHelpModal, setShowRequestHelpModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);
    const [showCostOfSaleModal, setShowCostOfSaleModal] = useState(false);
    const [showAdjustModal, setShowAdjustModal] = useState(false);
    const [compareProducts, setCompareProducts] = useState([]);
    const [sandboxScenario, setSandboxScenario] = useState({});
    const [marginImpactDetails, setMarginImpactDetails] = useState(marginDetails);
    const [createProductData, setCreateProductData] = useState(createProductModalInitialState);
    const [searchedProducts, setSearchedProducts] = useState([]);
    const [showCompareProductsScreen, setShowCompareProductsScreen] = useState(false);
    const [emissionPages, setEmissionPages] = useState(pagesObject);
    const [showConfirmationRemoveModal, setShowConfirmationRemoveModal] = useState(false);
    const [showOverwriteModal, setShowOverwriteModal] = useState(false);

    const resetContext = () => {
        setSelectedProducts([]);
        setEditedProducts([]);
        setSandbox({});
        setShowRequestHelpModal(false);
        setShowCreateModal(false);
        setShowContactModal(false);
        setShowCostOfSaleModal(false);
        setCompareProducts([]);
        setSandboxScenario({});
        setMarginImpactDetails(marginDetails);
        setCreateProductData(createProductModalInitialState);
        setSearchedProducts([]);
        setShowCompareProductsScreen(false);
        setEmissionPages(pagesObject);
        setShowConfirmationRemoveModal(false);
      };

    const value = {
        selectedProducts,
        setSelectedProducts,
        editedProducts,
        setEditedProducts,
        sandbox,
        setSandbox,
        showRequestHelpModal,
        setShowRequestHelpModal,
        showCreateModal,
        setShowCreateModal,
        showContactModal,
        setShowContactModal,
        showCostOfSaleModal,
        setShowCostOfSaleModal,
        showAdjustModal,
        setShowAdjustModal,
        compareProducts,
        setCompareProducts,
        sandboxScenario,
        setSandboxScenario,
        marginImpactDetails,
        setMarginImpactDetails,
        resetContext,
        createProductData,
        setCreateProductData,
        searchedProducts,
        setSearchedProducts,
        showCompareProductsScreen,
        setShowCompareProductsScreen,
        emissionPages,
        setEmissionPages,
        showConfirmationRemoveModal,
        setShowConfirmationRemoveModal,
        showOverwriteModal,
        setShowOverwriteModal,
    }

    return (
        <SandboxContext.Provider value={value}>
            {children}
        </SandboxContext.Provider>
    )
}