import WeightMatch from "../components/parts/ProductMatchingPage/WeightMatch";
import PercentageMatch from '../components/parts/ProductMatchingPage/PercentageMatch';
import ManualMatch from '../components/parts/ProductMatchingPage/ManualMatch';
import fetchMatchingEntriesNoSuggestions from "../utils/fetchMatchingEntriesNoSuggestions";
import fetchMatchingEntries from "../utils/fetchMatchingEntries";
import fetchMatchingEntriesWithSuggestions from "../utils/fetchMatchingEntriesWithSuggestions";

export const productMatchingComponents = {
  percentageMatch: {id: 'percentageMatch', tabText: 'Match by %', tabContent: (isLoading) => <PercentageMatch isLoading={isLoading}/>, fetchFn: fetchMatchingEntriesWithSuggestions},
  manualMatch: {id: 'manualMatch', tabText: 'Manual Match', tabContent: (isLoading) => <ManualMatch isLoading={isLoading}/>, fetchFn: fetchMatchingEntriesNoSuggestions},
  weightMatch: {id: 'weightMatch', tabText: 'Confirm Weight', tabContent: (isLoading) => <WeightMatch isLoading={isLoading}/>, fetchFn: fetchMatchingEntries}
}
