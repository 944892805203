import { useState, createContext } from 'react';

export const WeightConfirmContext = createContext({
  match: '',
  setMatch: () => {/* this is intentional */},
  isConfirmed: false,
  setIsConfirmed: () => {/* this is intentional */},
});

export default function WeightConfirmProvider({ children }) {
  const [match, setMatch] = useState();
  const [isConfirmed, setIsConfirmed] = useState(false)

  return (
    <WeightConfirmContext.Provider value={{match, setMatch, isConfirmed, setIsConfirmed}}>
      {children}
    </WeightConfirmContext.Provider>
  );
}
