import useIsValidToken from './hooks/useIsValidToken';
import Sidebar from './components/layout/Sidebar';
import Spinner from './components/templates/Spinner';
import AppRoutes from './routes/AppRoutes';
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_ANALYTICS);

export default function App() {
  const { loading } = useIsValidToken();

  return (
    <>
      {loading ? (
        <div className='w-screen h-screen'>
        <Spinner />
        </div>
      ) : (
        <div className='w-full h-full overflow-hidden'>
          <Sidebar>
            <AppRoutes />
          </Sidebar>
        </div>
      )}
    </>
  );
}
