export default function uploadCSV(dataType, files) {
  const formData = new FormData();

  const arr = Array.from(files);
  if (!Array.isArray(arr)) return Promise.resolve();

  if (arr.length > 1) {
    arr.forEach((file) => {
      formData.append('files', file);
    });
  } else {
    formData.append('files', arr[0]);
  }
  formData.append('dataType', dataType);

  const requestOptions = {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/matching-entries/import`,
    requestOptions,
  );
}
