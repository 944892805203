import * as Yup from 'yup';
import {Field, Form, Formik} from 'formik';
import Checkmark from '../../../../assets/svgs/ProductMatching/CheckMark';
import useWeightConfirm from '../../../hooks/useWeightConfirm';
import {useEffect} from 'react';
import {setAttributes} from '../../../utils/utils';
import Tooltip from "../../templates/Tooltip";

const {setEntryAttribute} = setAttributes;

const weightInputSchema = Yup.object().shape({
  weight: Yup.number().required().min(1),
  quantity: Yup.number().required().min(1),
  unitWeight: Yup.number().required().min(1)
});


export default function WeightMatchEntry({matchingEntry}) {
  const {isConfirmed, handleClick, handleWeightConfirm, handleRemove, checkConfirmation} = useWeightConfirm();

  useEffect(() => {
    checkConfirmation(matchingEntry?.confirmed);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function handleSubmit() {
    handleWeightConfirm(matchingEntry);
  }

  function calculateWeight(values, setFieldValue) {
    if (!values?.quantity && !values?.unitWeight) setFieldValue("weight", "0");
    setFieldValue("weight", values?.quantity * values?.unitWeight);
    return {weight: values?.quantity * values?.unitWeight, quantity: Number(values?.quantity), unitWeight: Number(values?.unitWeight)};
  }

  return <li className='mr-[5px]'>
    <Formik initialValues={{
      weight: matchingEntry?.weight ? matchingEntry?.weight : '',
      quantity: matchingEntry?.qty ? matchingEntry?.qty : '',
      unitWeight: matchingEntry?.displayed_weight ? matchingEntry?.displayed_weight : ''
    }} validationSchema={weightInputSchema} onSubmit={handleSubmit}>
      {({handleChange, handleBlur, values, setFieldValue}) => (
        <Form>
          <div
            className='w-full h-[88px] bg-White rounded-[12px] shadow-[0_5px_18px_rgba(0,0,0,0.1] px-[24px] flex items-center justify-between gap-[25px]'>
            <div className='gap-[10px] grid grid-cols-9'>
              <Tooltip
                text={`${matchingEntry?.title} / ${setEntryAttribute(matchingEntry?.caseSize)} / ${setEntryAttribute(matchingEntry?.uom)}`}
                className='w-full col-span-6'>
                <div
                  className='w-full h-[56px] border-Lightgrey border rounded-[8px] leading-[20px] flex items-center px-[16px] gap-[14px]'>
                  <div
                    className='text-Black main-font-bolded no-font-synthesis mt-[2px] truncate max-w-[34%] pointer-events-none'>
                    {matchingEntry?.title}
                  </div>
                  <div className='secondary-font-bolded'>/</div>
                  <p className='text-Black truncate secondary-font-bolded no-font-synthesis max-w-[33%]'>
                    {setEntryAttribute(matchingEntry?.caseSize)}
                  </p>
                  <div className='secondary-font-bolded'>/</div>
                  <p className='text-Black truncate secondary-font-bolded no-font-synthesis max-w-[33%]'>
                    {setEntryAttribute(matchingEntry?.uom)}
                  </p>
                </div>
              </Tooltip>
              <Field
                name='quantity'
                className='placeholder-Grey text-Black leading-[22px] flex items-center p-[16px] remove-focus secondary-font appearance-none h-[56px] border-Lightgrey border rounded-[8px] placeholder:text-center col-span-1'
                placeholder='-'
                onKeyDown={(event) => {
                  if (event.key === 'Escape') {
                    values.quantity = '';
                    handleRemove(matchingEntry?.id);
                  }
                }}
                onChange={(e) => {
                  handleChange(e);
                  handleRemove(matchingEntry?.id);
                }}
                onBlur={(e) => {
                  handleBlur(e);
                  calculateWeight(values, setFieldValue);
                }}
                value={values?.quantity}
              />
              <Field
                name='unitWeight'
                className='placeholder-Grey text-Black leading-[22px] flex items-center p-[16px] remove-focus secondary-font appearance-none h-[56px] border-Lightgrey border rounded-[8px] placeholder:text-center col-span-1'
                placeholder='-'
                onKeyDown={(event) => {
                  if (event.key === 'Escape') {
                    values.unitWeight = '';
                    handleRemove(matchingEntry?.id);
                  }
                }}
                onChange={(e) => {
                  handleChange(e);
                  handleRemove(matchingEntry?.id);
                }}
                onBlur={(e) => {
                  handleBlur(e);
                  calculateWeight(values, setFieldValue);
                }}
                value={values?.unitWeight}
              />
              <Field
                name='weight'
                className='placeholder-Grey text-Black leading-[22px] flex items-center p-[16px] remove-focus secondary-font appearance-none h-[56px] border-Lightgrey border rounded-[8px] placeholder:text-center col-span-1'
                placeholder='-'
                disabled={true}
                value={values?.weight}
              />
            </div>
            <div className='h-full border-l flex items-center pl-[24px]'>
              {!isConfirmed ?
                <button type='submit' className='green-button h-[40px] w-[134px] whitespace-nowrap'
                        disabled={!values?.weight || !values?.quantity || !values?.unitWeight}
                        onClick={() => {
                          const {weight, quantity, unitWeight} = calculateWeight(values, setFieldValue);
                          handleClick(weight, quantity, unitWeight);
                        }}>
                  Confirm weight
                </button> :
                <div className='w-[134px] main-font-bolded text-[15px] leading-[20px] flex items-center gap-[8px]'>
                  <Checkmark w={40} h={40}/>
                  <p>Confirmed</p>
                </div>}
            </div>
          </div>
        </Form>)}
    </Formik>
  </li>;
}