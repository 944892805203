import {Fragment, useState} from 'react'
import {Transition} from '@headlessui/react'

export default function ErrorMsg({type = 'selection', children}) {
  const [show, setShow] = useState(true)

  return (
    <>
      <div
        aria-live="assertive"
        className="-mt-[35px] min-w-[652px] flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-10 "
      >
        <div className="w-full flex flex-col items-center sm:items-end">
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="max-w-sm w-full bg-Error shadow-[0_5px_18px_rgba(0,0,0,0.1)] rounded-[12px] pointer-events-auto overflow-y-auto">
              <div className="p-4">
                <div className="relative">
                  <div className="pt-0.5">
                    <p
                      className="text-[15px] main-font-bolded leading-[24px] text-White tracking-wider uppercase">{type === 'parsing' ? 'File parsing error' : 'File selection error'}</p>
                    <div
                      className='mt-1 max-w-[600px] max-h-[300px] error-scrollbar overflow-y-auto text-[15px] font-medium leading-[22px] tracking-[-0.02em] text-White secondary-font whitespace-pre-wrap mr-[5.4px] break-words'>
                      {children}
                    </div>
                  </div>
                  <div className="absolute top-0 right-0">
                    <button
                      type="button"
                      className="rounded-md inline-flex text-White/90 hover:text-White "
                      onClick={() => {
                        setShow(false)
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <span className="h-5 w-5" aria-hidden="true">✕</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}
