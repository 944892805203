export default function SandBoxSvg() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00004 6.33333V10.3333M6.00004 8.33333H10M14.6667 11.6667C14.6667 12.0203 14.5262 12.3594 14.2762 12.6095C14.0261 12.8595 13.687 13 13.3334 13H2.66671C2.31309 13 1.97395 12.8595 1.7239 12.6095C1.47385 12.3594 1.33337 12.0203 1.33337 11.6667V2.33333C1.33337 1.97971 1.47385 1.64057 1.7239 1.39052C1.97395 1.14048 2.31309 1 2.66671 1H6.00004L7.33337 3H13.3334C13.687 3 14.0261 3.14048 14.2762 3.39052C14.5262 3.64057 14.6667 3.97971 14.6667 4.33333V11.6667Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
