import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './javascript/App';
import UserProvider from './javascript/context/UserContext';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import './index.css';

   Sentry.init({
   	dsn: 'https://cc62ed21b41d45eaa1a0c56145219184@o4503981826768896.ingest.sentry.io/4503982435926016',
   	integrations: [new BrowserTracing()],
   	tracesSampleRate: 1.0,
   });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<UserProvider>
			<App/>
		</UserProvider>
	</BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
