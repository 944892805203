import { useContext, useEffect, useState } from 'react';
import useIsLoggedIn from '../hooks/useIsLoggedIn';
import { UserContext } from '../context/UserContext';
import fetchValidation from '../utils/fetchValidation';

export default function useIsValidToken() {

  const { logout } = useIsLoggedIn();

  const { isValidToken, setIsValidToken } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  function tokenValidation() {
    !isValidToken && fetchValidation()
      .then(res => {
        if (res.status === 200) {
          validateToken();
        } else {
          logout();
          removeTokenValidation();
        }
      })
      .catch(err => {
        setError(err);
      }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    !isValidToken && tokenValidation();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function validateToken() {
    setIsValidToken(true);
  }

  function removeTokenValidation() {
    setIsValidToken(false);
  }

  return { isValidToken, loading, error, tokenValidation, removeTokenValidation };
}
