import useMatching from '../../../hooks/useMatching';
import { useCallback } from 'react';
import {setAttributes} from '../../../utils/utils';

const {setEntryAttribute, setEntryCarbonFootprint} = setAttributes;

export default function SearchDropdown({ id, options }) {
  const { handleDropdown } = useMatching();

  const renderOptions = useCallback(() => {
    if (options.length === 0) {
     return <p
        className='relative w-full h-[40px] rounded-[8px] select-none text-[14px] leading-[20px] py-[8px] pl-[8px] text-Black font-normal main-font-bolded'>No
        matches found...</p>
    }
    return options?.map((option, optionIdx) => (
      <button
        type='button'
        key={optionIdx}
        className='relative cursor-pointer w-[99.5%] h-[40px] first:mt-0 mt-[8px] rounded-[8px] select-none text-[15px] leading-[20px] py-[8px] pl-[8px] text-Black hover:bg-[rgba(127,201,160,0.4)] font-normal'
        onClick={() => {
          handleDropdown(option, id);
        }}
      >
        <div
          className='grid grid-cols-7 w-full text-left secondary-font '>
          <p className='main-font-bolded col-span-2 truncate text-[15px] py-[1px] w-full'>
            {setEntryAttribute(option?.title)}
          </p>
          <p className='ml-[5px] text-[15px] py-[1px] w-full'>
            1000g
          </p>
          <p
            className={!option?.category_product ? 'text-Grey text-[15px] truncate ml-[18px] w-full' : 'truncate text-[15px] ml-[18px] py-[1px] w-full'}>
            {setEntryAttribute(option?.category_product)}
          </p>
          <p className={!option?.percentage ? 'text-Grey text-[15px] ml-[30px] w-full' : 'ml-[30px] text-[15px] py-[1px] w-full'}>
            {setEntryAttribute(option?.percentage)}
          </p>
          <p className={!option?.carbon_footprint ? 'text-[15px] text-Grey ml-[45px] w-full' : 'text-[15px] ml-[45px] py-[1px] w-full'}>
            {setEntryCarbonFootprint(option?.carbon_footprint)}
          </p>
        </div>
      </button>)) ;
  }, [id, options, handleDropdown]);

  return (
    <div
      className='absolute w-full text-left mt-[8px] rounded-[8px] bg-White px-[8px] shadow-[0_5px_18px_rgba(0,0,0,0.1)] border-[1px] border-Grey z-1'>
      <div className='w-full max-h-[280px] list-scrollbar my-[8px] -mr-[10px] overflow-y-auto'>
        {renderOptions()}
      </div>
    </div>
  );
}