import React from "react";
import SandboxBoilerPlate from "../templates/SandboxBoilerPlate";
import MarginImpact from "../parts/SandBox/MarginImpact";

export default function SandboxMarginImpactPage() {
  return (
    <SandboxBoilerPlate title="Margin impact" showCompareOtherProducts={true}>
      <MarginImpact />
    </SandboxBoilerPlate>
  );
}
