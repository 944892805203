import useUploadCSVForm from "../../../hooks/useUploadCSVForm";
import CurlyArrowSvg from "../../../../assets/svgs/UploadCSV/CurlyArrowSvg";

export default function DragBackDrop() {
  const {dragActive, handleDrag, handleDrop} = useUploadCSVForm();

  return dragActive && (
    <div
      className='w-full h-full fixed top-0 left-0 bg-Black opacity-50 z-2'
      onDragOver={handleDrag}
      onDragLeave={handleDrag}
      onDrop={handleDrop}
    >
      <div className='absolute right-5 bottom-5'>
        <div className='flex flex-row gap-1'>
          <div className='flex justify-start items-end'>
          <CurlyArrowSvg/>
            <p className='text-white text-lg secondary-font'> Drag and drop wherever you want </p>
          </div>
        </div>
      </div>
    </div>
  )
}