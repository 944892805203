export default async function findMatch(query) {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/products/search-products`, {
      method: 'POST', headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({query}),
    });
    return await res.json();
  } catch (err) {
    console.error(err)
  }
}