export default function LockSvg() {
  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 7H9.5V4C9.5 2.61929 8.38071 1.5 7 1.5C5.61929 1.5 4.5 2.61929 4.5 4V7ZM3 7H2C0.895431 7 0 7.89543 0 9V15C0 16.1046 0.89543 17 2 17H12C13.1046 17 14 16.1046 14 15V9C14 7.89543 13.1046 7 12 7H11V4C11 1.79086 9.20914 0 7 0C4.79086 0 3 1.79086 3 4V7Z"
        fill="#DBDADA"
      />
    </svg>
  );
}
