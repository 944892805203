/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import SandboxToolTip from "../../../templates/SandboxToolTip";
import { useInView } from "react-intersection-observer";
import TickBoxSvg from "../../../../../assets/svgs/Sandbox/TickBoxSvg";
import CheckSvg from "../../../../../assets/svgs/Sandbox/CheckSvg";
import InfoSvg from "../../../../../assets/svgs/Sandbox/InfoSvg";
import Spinner from "../../../templates/Spinner";

export default function ShowProducts({stateButtons, pageDetails, selectProduct, handleSearchButton, sendingRequest}) {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      handleSearchButton();
    }
  }, [inView]);

  return (
    <div className="pl-[64px] pr-[54px]">
      <div className="grid grid-cols-9 mt-[24px] text-base text-left secondary-font text-[15px]">
        <div className="pl-[24px] col-span-2">Product Name</div>
        <div>Category</div>
        <div className={`${!stateButtons.product && "col-span-5"}`}>
          CO2 per KG
        </div>
        {stateButtons.product && (
          <div className="col-span-4 flex">
            <div className="mr-[9px]">Verified</div>
            <SandboxToolTip text="Whenever possible, select verified products which come directly from the manufacturer. Please note that if no verified product is available, a similar product from a supplier will be used, which may result in some variation in CO2 values." details={'left-[10px] top-[-30px] w-[100px] lg:left-[120px] lg:w-[500px]'}>
              <div className="mb-[8px] cursor-pointer">
                <InfoSvg />
              </div>
            </SandboxToolTip>
          </div>
        )}
      </div>
      <div className="secondary-font">
        {pageDetails.products.map((product, index) => (
          <div key={product.product_id} className="relative w-full"  ref={index === pageDetails.products.length - 1 ? ref : null}>
            <div>
              <div className="min-h-[80px] grid grid-cols-9 items-center mb-[24px] text-[15px] bg-white text-Grey text-left border rounded-xl shadow-sm leading-[20px]">
                <div className="col-span-2">
                  <SandboxToolTip text={product.product} details={'left-[120px] top-[-30px] w-full max-w-[300px]'}>
                    <div className="pl-[24px] leading-5">
                      {product.product}
                    </div>
                  </SandboxToolTip>
                </div>
                <div>{product.category}</div>
                <div className={`${!stateButtons.product && "col-span-4"}`}>
                  {product.co2perkg && parseFloat(product.co2perkg).toFixed(2)}
                </div>
                {stateButtons.product && (
                  <div className="col-span-3">
                    <div className="ml-[20px]">
                      <CheckSvg selected={product.verified} />
                    </div>
                  </div>
                )}
                <div
                  className="flex flex-row justify-end cursor-pointer mr-[30px]"
                  onClick={() =>
                    selectProduct(index, !pageDetails.products[index].selected)
                  }>
                  <TickBoxSvg
                    selected={
                      pageDetails.products[index].selected ? true : false
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {sendingRequest && (
        <div>
          <Spinner />
        </div>
      )}
    </div>
  );
}
