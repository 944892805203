export default async function fetchSuppliers() {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/supplier/suppliers-for-company`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return res.json();
  } catch (err) {
    console.error(err);
  }
}
