import React, { useContext } from "react";
import { SandboxContext } from "../../../../context/SandboxContext";
import { analyticsClickedButton } from "../../../../utils/utils";

export default function BannerSelectedProduct({ compareProducts, resetPageDetails, totalPercentage }) {
  const {setShowCostOfSaleModal} = useContext(SandboxContext);

  return (
    <>
      {compareProducts.length > 0 && (
        <div className="absolute bottom-0 w-full bg-[#FBFBF8]">
          <div className="left-0 w-full h-0 border border-DarkStone"></div>
            <div className="flex flex-row justify-center my-[36px]">
              <div
                // className={`${compareProducts.length === 3 ? 'opacity-60 cursor-default' : 'cursor-pointer'} green-button mr-[16px]`}
                className={`cursor-pointer green-button mr-[16px]`}
                onClick={() => {
                  analyticsClickedButton('Compare products', 'Add another');
                  resetPageDetails();
                }}>
                Add another
              </div>
              <div className={`${totalPercentage === 100 ? 'cursor-pointer' : 'opacity-60 cursor-default'} yellow-button`}
                onClick={() => {
                  if (totalPercentage === 100) {
                    analyticsClickedButton('Compare products', 'Run sandbox');
                    setShowCostOfSaleModal(true);
                  }
                }}>
                  Run Sandbox
                </div>
            </div>
        </div>
      )}
    </>
  );
}
