import Spinner from '../../templates/Spinner';
import Pagination from '../../templates/Pagination';
import WeightConfirmList from './WeightConfirmList';
import useProductMatching from "../../../hooks/useProductMatching";

export default function WeightMatch({isLoading}) {
  const {confirmedEntries} = useProductMatching();

  return <div className='h-full flex flex-col mx-[64px] mt-[28px]'>
    <div className='grid grid-cols-9 mx-[24px] text-[15px] leading-[20px] secondary-font mr-[200px]'>
      <p className='col-span-6'>Product name</p>
      <p className='col-span-1 truncate'>Quantity</p>
      <p className='col-span-1 truncate'>Unit Weight</p>
      <p className='col-span-1 truncate'>Weight (g)</p>
    </div>
    {isLoading ? <Spinner what='Abstract Matching'/> : <WeightConfirmList/>}
    <Pagination modalTrigger={!!Object.keys(confirmedEntries)?.length} />
  </div>;
}