import NoSidebar from "../parts/Sidebar/NoSidebar";
import {classNames} from "../../utils/utils";
import ReactGA from "react-ga4";

export default function PageBoilerplate({children, name, noStyle = false, left=false}) {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: name});

  return (
    <div className='w-full h-full relative'>
      <div className={classNames(noStyle ? 'bg-White' : 'bg-Stone/30','flex justify-center pb-[2px] w-full')}>
        {noStyle && <NoSidebar/>}
      <h1
        className={classNames(left ? 'justify-start ml-[64px]' : 'justify-center' ,'w-full flex  items-center text-center text-[34px] leading-[44px] main-font-bolded text-Black min-h-[145px] tracking-wide relative')}>
        {name}
      </h1>
      <title>{name}</title>
      </div>
      {!noStyle && <div className='left-0 w-full h-0 border border-DarkStone'></div>}
      {children}
    </div>
  );
}