import {createContext, useState} from 'react';

export const ProductMatchingContext = createContext({
  entriesToBeConfirmed: [],
  setEntriesToBeConfirmed: () => {/* this is intentional */
  },
  pendingEntries: [],
  setPendingEntries: () => {/* this is intentional */},
  confirmedEntries: {},
  setConfirmedEntries: () => {/* this is intentional */
  },
});

export default function ProductMatchingProvider({children}) {
  const [entriesToBeConfirmed, setEntriesToBeConfirmed] = useState([])
  const [pendingEntries, setPendingEntries] = useState([])
  const [confirmedEntries, setConfirmedEntries] = useState({})

  return (
    <ProductMatchingContext.Provider value={{
      entriesToBeConfirmed,
      setEntriesToBeConfirmed,
      pendingEntries,
      setPendingEntries,
      confirmedEntries,
      setConfirmedEntries
    }}>
      {children}
    </ProductMatchingContext.Provider>
  );
}