import React, { memo } from "react";

const InfoIconToolTip = memo((props) => {
  return (
    <span className={`group relative ${props.class} cursor-pointer z-0`}>
      <div
        className={`${props.details} pointer-events-none secondary-font absolute -translate-x-1/2 rounded bg-black px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:content-[""] group-hover:opacity-100 z-2`}>
        {props.text}
      </div>
      {props.children}
    </span>
  );
});

export default InfoIconToolTip;
