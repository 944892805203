/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { SandboxContext } from "../../../../context/SandboxContext";
import { analyticsClickedButton, analyticsInputChange, returnIsValidNumberInput } from "../../../../utils/utils";
import { useNavigate } from "react-router-dom";
import InfoSvg from "../../../../../assets/svgs/Sandbox/InfoSvg";
import GeneralToolTip from "../../../templates/GeneralToolTip";
import SandboxToolTip from "../../../templates/SandboxToolTip";

export default function CostOfSaleModal({showCompareProducts, sandboxProduct, sandboxProductUpdate}) {
  const {
    showCostOfSaleModal,
    setShowCostOfSaleModal,
    compareProducts,
    setCompareProducts,
    marginImpactDetails,
    setMarginImpactDetails,
    setSandboxScenario,
  } = useContext(SandboxContext);
  const [products, setProducts] = useState([]);
  const [existingProduct, setExistingProduct] = useState({});
  const [costOfSale, setCostOfSale] = useState(0);

  const navigate = useNavigate();

  const handlePriceChange = (id, price) => {
    const checkedInput = returnIsValidNumberInput(price);

    if (!checkedInput) return;

    const mappedProducts = products.map((product) => {
      if (product.product_id === id) {
        product.price = price.length === 1 && price === "." ? "0." : price.replace(",", ".")
      }

      return product;
    });

    setProducts(mappedProducts);
  };

  const handleSubmitButton = () => {
    const newProducts = compareProducts.map((product) => {
      const foundProduct = products.find((p) => p.product_id === product.product_id);

      if (foundProduct) {
        product.price = foundProduct.price;
        product.percentage = foundProduct.percentage;
        product.weight = foundProduct.weight;
        product.totalCo2 = foundProduct.totalCo2;

        return foundProduct;
      }

      return product;
    });

    //use reduce to calculate total percentage
    const totalPercentage = newProducts.reduce((acc, product) => {
      if (product.percentage.toString().length > 0) {
        return acc + Number(product.percentage);
      }

      return acc;
    }, 0);

    if (totalPercentage !== 100) {
      setCompareProducts(newProducts);
    } else {
      const totalWeight = newProducts.reduce((acc, product) => {
        if (product.weight.toString().length > 0) {
          return acc + Number(product.weight);
        }

        return acc;
      }, 0).toFixed(2);

      const totalCo2 = newProducts.reduce((acc, product) => {
        if (product.totalCo2.toString().length > 0) {
          return acc + Number(product.totalCo2);
        }

        return acc;
      }, 0).toFixed(2);

      const totalCo2PerKg = parseFloat(totalCo2 / totalWeight).toFixed(2);

      const totalPrice = (newProducts.reduce((acc, product) => {
        if (product.price.toString().length > 0) {
          return acc + Number(product.price) * Number(product.weight);
        }

        return acc;
      }, 0) / totalWeight).toFixed(2);

      const sandboxScenario = {
        co2perkg: totalCo2PerKg,
        totalCo2: totalCo2,
        weight: totalWeight,
        price: totalPrice
      };

      setSandboxScenario(sandboxScenario);
      navigate("/sandbox/margin-impact");
    }

    setMarginImpactDetails({ ...marginImpactDetails, costOfSale: costOfSale });
    setShowCostOfSaleModal(false);
  };

  const handleUpdateSandBoxProductPrice = (value) => {
    const checkedInput = returnIsValidNumberInput(value);

    if (!checkedInput) return;

    setExistingProduct({ ...existingProduct, price: value.replace(",", ".") });
    sandboxProductUpdate(value.replace(",", "."));
  };

  useEffect(() => {
    setCostOfSale(marginImpactDetails.costOfSale);
    // filter out products that have no price

    if (!showCompareProducts) {
      const filteredProducts = compareProducts.filter((product) => String(product.price) === "0" || String(product.price) === "");

      setProducts(filteredProducts);
    } else {
      setProducts(compareProducts);
    }

    if(sandboxProduct?.price === 0 || sandboxProduct?.price === "") {
      setExistingProduct(sandboxProduct);
    }
    
  }, []);

  return (
    <div>
      <>
        <Transition.Root show={showCostOfSaleModal} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10 top-2/4 max-w-[972px]"
            onClose={() => {
              setShowCostOfSaleModal(false);
            }}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full md:items-end justify-center p-4 text-center items-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 scale-95 md:scale-0">
                  <Dialog.Panel
                    className={`${products.length > 0 ? "max-w-[972px]" : "max-w-[570px]"
                    } relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full my-auto`}>
                    <div className="mt-[56px]">
                      <div className="text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-[34px] text-center font-bold text-black main-font-bolded mb-[32px]">
                          Cost of sale
                        </Dialog.Title>
                      </div>
                      <div className="flex justify-center items-center mb-[32px]">
                        <div className="mt-2 flex rounded-md shadow-sm max-w-[304px] w-full h-[56px]">
                          <input
                            type="text"
                            name="costOfSale"
                            id="costOfSale"
                            className="block text-[16px] secondary-font w-full min-w-0 flex-1 rounded-none rounded-l-md border-0 py-1.5 text-black ring-1 ring-inset ring-gray-300 placeholder:text-black focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
                            placeholder="Cost of sale"
                            value={costOfSale}
                            onChange={(e) => {
                              analyticsInputChange('Cost of Sale Modal', 'Cost of sale', e.target.value)
                              setCostOfSale(e.target.value);
                            }}
                          />
                          <div className="inline-flex justify-center w-[50px] items-center rounded-r-md border border-l-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                            <span>£</span>
                          </div>
                        </div>
                        <div className="h-full mt-4 ml-4">
                          <GeneralToolTip
                            details={'top-[-85px] w-[400px] right-[-250px] lg:right-[-300px]'}
                            text="Cost of Sale (COS) - is the accumulated total of all food & drink spend for your business in the period you have selected">
                            <div className="mb-[8px] cursor-pointer">
                              <InfoSvg />
                            </div>
                          </GeneralToolTip>
                        </div>
                      </div>
                      {(products.length > 0 || Object.keys(existingProduct).length > 0) && (
                        <>
                          <div
                            className={`${
                              products.length > 0
                                ? "max-w-[892px]"
                                : "max-w-[570px]"
                            } mx-[40px] grid grid-cols-6 grid-auto-flow mt-[24px] text-left secondary-font text-[15px] w-full items-end secondary-font`}>
                            <div className="col-span-2"></div>
                            <div>CO2 per KG</div>
                            <div>CO2 in Period</div>
                            <div>Quantity KG</div>
                            <div>Price Per KG</div>
                          </div>
                          <div className="mt-[6px] mx-[40px]">
                            {Object.keys(existingProduct).length > 0 && (
                              <div
                              className="w-full secondary-font text-left grid grid-cols-6 grid-auto-flow items-center mb-[16px] text-base text-[15px] text-DarkGrey bg-white border rounded-xl shadow-sm leading-[20px] min-h-[80px]">
                              <div className="col-span-2">
                              <SandboxToolTip text={existingProduct.name} details={'left-[170px] top-[-30px] w-[300px]'}>
                                <div className="pl-[24px] text-base leading-5 main-font-bolded text-black text-[16px] truncate">
                                  {existingProduct.name}
                                </div>
                              </SandboxToolTip>
                              </div>
                              <div>{existingProduct.co2perkg}</div>
                              <div>{parseFloat(existingProduct.totalCo2).toFixed(2)}</div>
                              <div>{parseFloat(existingProduct.weight).toFixed(2)} kg</div>
                              <div className="relative max-w-[104px] mr-[37px] min-w-[50px]">
                                <input
                                  className="w-full border rounded-[8px] py-[10px] px-[12px] pl-[20px] text-[15px]"
                                  value={existingProduct.price}
                                  onChange={(e) => handleUpdateSandBoxProductPrice(e.target.value)}
                                />
                                <span className="absolute left-[10px] top-[10px]">
                                  £
                                </span>
                              </div>
                            </div>
                            )}
                            {products.map((product) => (
                              <div
                                className="w-full secondary-font text-left grid grid-cols-6 grid-auto-flow items-center mb-[16px] text-base text-[15px] text-Grey bg-white border rounded-xl shadow-sm leading-[20px] min-h-[80px]"
                                key={product.product_id}>
                                <div className="col-span-2">
                                <SandboxToolTip text={product.product} details={'left-[170px] top-[-30px] w-[300px]'}>
                                  <div className="pl-[24px] text-base leading-5 main-font-bolded text-black text-[16px] truncate">
                                    {product.product}
                                  </div>
                                </SandboxToolTip>
                                </div>
                                <div>{product.co2perkg}</div>
                                <div>{product.totalCo2}</div>
                                <div>{product.weight} kg</div>
                                <div className="relative max-w-[104px] mr-[37px] min-w-[50px]">
                                  <input
                                    className="w-full border rounded-[8px] py-[10px] px-[12px] pl-[20px] text-[15px]"
                                    value={product.price}
                                    onChange={(e) =>
                                      handlePriceChange(
                                        product.product_id,
                                        e.target.value
                                      )
                                    }
                                  />
                                  <span className="absolute left-[10px] top-[10px]">
                                    £
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                      <div className="mt-[61px] w-full h-[136px] bg-[#FBFBF8] border-t-[1px] border-DarkStone">
                        <div className="flex justify-between mx-[40px] my-[24px] items-center">
                          <div
                            className={` ${
                              products.length > 0
                                ? "max-w-[454px]"
                                : "max-w-[271px]"
                            } secondary-font text-[16px] text-Grey`}>
                            If the price per KG/ Costs of Sales Price fields are
                            not completed the Cost of Sale Saving will not be
                            calculated.
                          </div>
                          <div
                            className="green-button h-[40px] cursor-pointer"
                            onClick={() => {
                              analyticsClickedButton('Cost of Sale Modal', 'Submit Changes');
                              handleSubmitButton()}}>
                            Submit Changes
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    </div>
  );
}
