import React from "react";
import SelectExistingProduct from "../parts/SandBox/SelectExistingProduct";
import SandboxBoilerPlate from "../templates/SandboxBoilerPlate";

export default function SandboxSelectExistingProduct() {
  return (
    <SandboxBoilerPlate title="Select Existing Product" showCreateButton={false}>
        <SelectExistingProduct />
    </SandboxBoilerPlate>
  );
}
