import { useCallback } from 'react';
import WeightConfirmProvider from '../../../context/WeightConfirmContext';
import WeightMatchEntry from './WeightMatchEntry';
import useProductMatching from '../../../hooks/useProductMatching';

export default function WeightConfirmList() {
  const { entriesToBeConfirmed } = useProductMatching();

  const renderEntries = useCallback(() => {
    if (entriesToBeConfirmed?.length === 0) {
      return <p
        className=' flex justify-center items-center w-full h-full rounded-[8px] font-[14px] leading-[20px] py-[8px] pl-[8px] text-Black font-normal main-font-bolded'>No
        weights to confirm...</p>;
    }
    return entriesToBeConfirmed?.map(matchingEntry =>
      <WeightConfirmProvider key={matchingEntry.id}>
        <WeightMatchEntry matchingEntry={matchingEntry} />
      </WeightConfirmProvider>);
  }, [entriesToBeConfirmed]);

  return <ul className='w-full flex flex-col gap-[24px] mt-[6px] min-h-[calc(100%_-_307px)] max-h-[calc(100%_-_307px)] list-scrollbar'>
    {renderEntries()}
  </ul>
}