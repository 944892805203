import {createContext, useState} from 'react';

export const UploadCSVFormContext = createContext({
  files: [],
  setFiles: () => {/*This is intentional*/
  },
  dataType: 'Purchasing Data',
  setDataType: () => {/* This is intentional*/
  },
  wrongExtensionFiles: [],
  setWrongExtensionFiles: () => {/*This is intentional*/
  },
  largeFiles: [],
  setLargeFiles: () => {/*This is intentional*/
  },
  duplicateFiles: [],
  setDuplicateFiles: () => {/*This is intentional*/
  },
  brokenHeadersFiles: [],
  setBrokenHeadersFiles: () => {/*This is intentional*/
  },
  emptyFiles: [],
  setEmptyFiles: () => {/*This is intentional*/
  },
  wrongTypeFiles: [],
  setWrongTypeFiles: () => {/*This is intentional*/
  },
  exceededRowsFiles: [],
  setExceededRowsFiles: () => {/*This is intentional*/
  },
  badDateFiles: [],
  setBadDateFiles: () => {/*This is intentional*/
  },
  missingDataFiles: [],
  setMissingDataFiles: () => {/*This is intentional*/
  },
  dragActive: false,
  setDragActive: () => {/*This is intentional*/
  },
  isDropdownActive: false,
  setIsDropdownActive: () => {/*This is intentional*/
  },
  uploading: false,
  setUploading: () => {/*This is intentional*/
  },
  partialUpload: false,
  setPartialUpload: () => {/*This is intentional*/
  },
  uploadDone: false,
  setUploadDone: () => {/*This is intentional*/
  },
  uploadError: false,
  setUploadError: () => {/*This is intentional*/
  },
});

export default function UploadCSVFormProvider({children}) {
  const [files, setFiles] = useState([]);
  const [dataType, setDataType] = useState('Purchasing Data');

  const [wrongExtensionFiles, setWrongExtensionFiles] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [duplicateFiles, setDuplicateFiles] = useState([]);

  const [brokenHeadersFiles, setBrokenHeadersFiles] = useState([]);
  const [emptyFiles, setEmptyFiles] = useState([]);
  const [wrongTypeFiles, setWrongTypeFiles] = useState([]);
  const [exceededRowsFiles, setExceededRowsFiles] = useState([]);
  const [badDateFiles, setBadDateFiles] = useState([]);
  const [missingDataFiles, setMissingDataFiles] = useState([]);

  const [dragActive, setDragActive] = useState(false);
  const [isDropdownActive, setIsDropdownActive] = useState(false);

  const [uploading, setUploading] = useState(false);
  const [partialUpload, setPartialUpload] = useState(false);
  const [uploadDone, setUploadDone] = useState(false);
  const [uploadError, setUploadError] = useState(false);


  return (
    <UploadCSVFormContext.Provider value={{
      files,
      setFiles,
      dataType,
      setDataType,
      wrongExtensionFiles,
      setWrongExtensionFiles,
      wrongTypeFiles,
      setWrongTypeFiles,
      largeFiles,
      setLargeFiles,
      duplicateFiles,
      setDuplicateFiles,
      brokenHeadersFiles,
      setBrokenHeadersFiles,
      emptyFiles,
      setEmptyFiles,
      exceededRowsFiles,
      setExceededRowsFiles,
      badDateFiles,
      setBadDateFiles,
      missingDataFiles,
      setMissingDataFiles,
      dragActive,
      setDragActive,
      uploading,
      setUploading,
      partialUpload,
      setPartialUpload,
      uploadDone,
      setUploadDone,
      uploadError,
      setUploadError,
      isDropdownActive,
      setIsDropdownActive,
    }}>
      {children}
    </UploadCSVFormContext.Provider>
  );
}
