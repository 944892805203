import { Routes, Route } from "react-router-dom";
import UploadCSV from "../components/pages/UploadCSV";
import NotFound from "../components/pages/NotFound";
import Dashboards from "../components/pages/Dashboards";
import { sidebarLinks } from "../constants/sidebarLinks";
import ProductMatching from "../components/pages/ProductMatching";
import SandboxInfoPage from "../components/pages/SandboxInfoPage";
import SandboxSelectExistingProduct from "../components/pages/SandboxSelectExistingProduct";
import { baseUrl } from "../constants/baseUrl";
import SandboxSearchProducts from "../components/pages/SandboxSearchProducts";
import SavedSandboxesPage from "../components/pages/SavedSandboxesPage";
import SandboxSelectedProducts from "../components/pages/SandboxSelectedProducts";
import SandboxContextProvider from "../context/SandboxContext";
import SandboxMarginImpactPage from "../components/pages/SandboxMarginImpactPage";

function SandBoxRoutes() {
  return (
    <SandboxContextProvider>
      <Routes>
        <Route path={baseUrl.sandbox} element={<SandboxInfoPage />} />
        <Route
          path={baseUrl.selectProduct}
          element={<SandboxSelectExistingProduct />}
        />
        <Route
          path={baseUrl.searchProducts}
          element={<SandboxSearchProducts />}
        />
        <Route path={baseUrl.savedSandboxes} element={<SavedSandboxesPage />} />
        <Route path={baseUrl.marginImpact} element={<SandboxMarginImpactPage />} />
        <Route
          path={baseUrl.selectedProducts}
          element={<SandboxSelectedProducts />}
        />
      </Routes>
    </SandboxContextProvider>
  );
}

export default function CustomerSpaceRoutes() {
  return (
    <>
      <SandBoxRoutes />
      <Routes>
        <Route>
          {Object.values(sidebarLinks.administration.subLinks).map((value) => (
            <Route path={value.href} element={<UploadCSV />} key={value.name} />
          ))}
        </Route>
        {process.env.REACT_APP_ENVIRONMENT !== "production" && (
          <Route path={baseUrl.productMatching} element={<ProductMatching />} />
        )}
        <Route>
          {Object.values(sidebarLinks.dashboards.subLinks).map((value) => (
            <Route
              path={value.href}
              element={
                <Dashboards
                  slug={value.slug}
                  isFiltered={value.isFiltered}
                  name={value.name}
                  text={value.text}
                />
              }
              key={value.name}
            />
          ))}
        </Route>
        {/*<Route path={baseUrl.myAccount} element={<MyAccount />} />*/}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}
