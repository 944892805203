import { useState, createContext } from 'react';
import {productMatchingComponents} from "../constants/productMatchingComponents";

export const ProductMatchingComponentContext = createContext({
  component: productMatchingComponents.percentageMatch,
  setComponent: () => {/* this is intentional */},
});

export default function ProductMatchingComponentProvider({ children }) {
  const [component, setComponent] = useState(productMatchingComponents.percentageMatch);

  return (
    <ProductMatchingComponentContext.Provider value={{component, setComponent}}>
      {children}
    </ProductMatchingComponentContext.Provider>
  );
}
