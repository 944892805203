import {useEffect, useRef, useState} from 'react';
import useUploadCSVForm from '../../../hooks/useUploadCSVForm';
import FileIcon from '../../../../assets/svgs/UploadCSV/FileIcon';
import {classNames} from '../../../utils/utils';
import UploadResultMessages from './UploadResultMessages';

export default function UploadCSVCard({file, error}) {
  const {uploading, uploadDone, partialUpload, uploadError, handleRemove, uploadFinish} = useUploadCSVForm();
  const [isUploading, setIsUploading] = useState(false);
  const uploadBar = useRef(null);

  useEffect(() => {
      if (uploading && !error) setIsUploading(true)
      const observer = new ResizeObserver(() => {
        if (uploadBar?.current?.offsetWidth === uploadBar?.current?.parentElement?.offsetWidth) {
          uploadFinish();
        }
      })
      isUploading && observer.observe(uploadBar?.current)
      return () => {
        isUploading && observer.disconnect();
      }
    }
    , [uploading, error, uploadFinish, uploadBar?.current?.offsetWidth, isUploading, uploadError]);

  function remove() {
    handleRemove(file.name);
  }


  return (
    <li className='h-[80px] flex border-b last:border-b-0 border-Stone ml-[32px] mr-[23px]'>
      <div className='w-full flex flex-row gap-[16px] items-center '>
        <div className='flex justify-center  '>
          <FileIcon/>
        </div>
        <div className='w-full flex flex-col justify-start h-[40px] truncate'>
          <div className='flex flex-row justify-between -mt-[2px] gap-[25px]'>
            <h3
              className={classNames(!error ? 'text-Black' : 'text-Error', 'main-font-bolded text-[15px] leading-[20px] truncate')}>
              {file.name}
            </h3>
            {(error || partialUpload || uploadDone) ? (
              <UploadResultMessages error={error}/>
            ) : (<div className='flex'>
              <button onClick={remove}
                      className='text-Grey hover:text-Error text-[15px] leading-[20px] font-medium cursor-pointer disabled:opacity-50 disabled:cursor-default disabled:hover:text-Grey secondary-font'
                      disabled={uploading}
              >
                Remove
              </button>
            </div>)}
          </div>
          <div id='progress-bar' className='mt-[10px]'>
            <div className='h-[8px] bg-Green/30 rounded-[8px] w-full'>
              <div ref={uploadBar}
                   className={`h-[8px] bg-Green rounded-[8px] ${isUploading && !error ? 'w-full' : 'w-0'} transition-width duration-[6000ms]`}/>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
