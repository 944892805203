/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import HappySandbox from "../../../../assets/photos/HappySandbox.png";
import SadSandbox from "../../../../assets/photos/SadSandbox.png";
import { SandboxContext } from "../../../context/SandboxContext";
import CostOfSaleModal from "./Modals/CostOfSaleModal"; 
import moment from "moment";
import fetchCreateSandbox from "../../../utils/fetchCreateSandbox";
import { useNavigate } from "react-router-dom";
import AdjustUnits from "./Modals/AdjustUnits";
import OverwriteSandboxModal from "./Modals/OverwriteSandboxModal";
import { useReactToPrint } from "react-to-print";
import SearchProducts from "./SearchProducts";
import ShowSelectedProducts from "./Components/ShowSelectedProducts";
import { analyticsClickedButton } from "../../../utils/utils";

export default function MarginImpact() {
  const { setShowCostOfSaleModal, sandboxScenario, sandbox, marginImpactDetails, editedProducts, compareProducts, setShowAdjustModal, setShowOverwriteModal } = useContext(SandboxContext);
  const [existingProduct, setExistingProduct] = useState({});
  const [dataRange, setDataRange] = useState([null, null]);
  const [showDownloadComponent, setShowDownloadComponent] = useState(false);
  const [details, setDetails] = useState({
    costOfSaleSaving: 0,
    co2ImpactKg: 0,
    costOfSaleSavingPercentage: 0,
    co2Impact: 0,
    totalCo2Kg: 0,
  }); 

  // for downloading PDF
  const componentRef = useRef();

  const navigate = useNavigate();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'MySandbox',
    // onAfterPrint: () => setShowDownloadComponent(false),
  });

  const handleSaveButton = () => {
    if (sandbox.id !== null) {
      setShowOverwriteModal(true);
    } else {
      handleSubmitSandbox();
    }
  }

  const handleSubmitSandbox = async () => {
    const sandboxToCreate = {
      id: sandbox.id,
      emissionChoices: editedProducts.map((product) => { return Number(product.product_id) }),
      emissionPercentages: editedProducts.map((product) => { return Number(product.percentage) }),
      emissionPrices: editedProducts.map((product) => { return Number(product.price) }),
      emissionWeights: editedProducts.map((product) => { return Number(product.weight) }),
      emissionCo2InPeriod: editedProducts.map((product) => { return Number(product.totalCo2) }),
      substitutionPercentages: compareProducts.map((product) => { return Number(product.percentage) }),
      substitutionChoices: compareProducts.map((product) => { return Number(product.product_id) }),
      substitutionTypes: compareProducts.map((product) => { return product.collectionType }),
      substitutionPrices: compareProducts.map((product) => { return Number(product.price) }),
      substitutionWeights: compareProducts.map((product) => { return Number(product.weight) }),
      substitutionCo2InPeriod: compareProducts.map((product) => { return Number(product.totalCo2) }),
      toSandboxPercentage: Number(sandbox.toSandboxPercentage),
      name: sandbox.name,
      query: marginImpactDetails.query,
      costOfSale: Number(marginImpactDetails.costOfSale),
      costOfSaleSaving: Number(details.costOfSaleSaving),
      co2Impact: Number(details.co2Impact),
      co2ImpactKg: Number(details.co2ImpactKg),
      costOfSaleSavingPercentage: Number(details.costOfSaleSavingPercentage),
      revenue: Number(marginImpactDetails.revenue),
      totalCo2: Number(marginImpactDetails.totalCo2),
      dateRange: [marginImpactDetails.dataRange[0], marginImpactDetails.dataRange[1]],
      sandboxMix: {
        co2perkg: Number(existingProduct.co2perkg),
        co2InPeriod: Number(existingProduct.totalCo2),
        quantity: Number(existingProduct.weight),
        price: Number(existingProduct.price),
      },
      originalMix: {
        co2perkg: Number(sandboxScenario.co2perkg),
        co2InPeriod: Number(sandboxScenario.totalCo2),
        quantity: Number(sandboxScenario.weight),
        price: Number(sandboxScenario.price),
      }
    };

    const result = await fetchCreateSandbox(sandboxToCreate);
    
    if (result.id) {
      navigate ("/sandbox");
    }
  }
  
  useEffect(() => {
    if (Object.keys(sandbox).length !== 0) {
      let newProduct = {...sandbox};
      newProduct.weight = (sandbox.weight * (sandbox.toSandboxPercentage / 100)).toFixed(2);
      newProduct.totalCo2 = (newProduct.weight * sandbox.co2perkg).toFixed(2);
  
      delete newProduct.toSandboxPercentage;
  
      const startDate = moment(marginImpactDetails.dataRange[0]).format("DD/MM/YYYY");
      const endDate = moment(marginImpactDetails.dataRange[1]).format("DD/MM/YYYY");
  
      setDataRange([startDate, endDate]);
  
      const costOfSaleSaving =  sandboxScenario.weight * sandboxScenario.price - newProduct.weight * newProduct.price;
  
      const costOfSaleSavingPercentage = newProduct.weight * newProduct.price !== 0 ? (costOfSaleSaving / Number(marginImpactDetails.costOfSale) * 100).toFixed(2) : 0;
      const co2ImpactKg =  sandboxScenario.totalCo2 - newProduct.totalCo2;
      const co2Impact = ((co2ImpactKg / marginImpactDetails.totalCo2) * 100).toFixed(2);
  
      setDetails({
        ...details,
        costOfSaleSaving: costOfSaleSaving.toFixed(2),
        co2ImpactKg: co2ImpactKg.toFixed(2),
        co2Impact: co2Impact,
        costOfSaleSavingPercentage: costOfSaleSavingPercentage,
      });
  
      setExistingProduct(newProduct);
    } else {
      navigate("/sandbox");
    }
  }, [sandbox, marginImpactDetails]); 

  useEffect(() => {
    if (showDownloadComponent === true) {
      setTimeout(() => {
        handlePrint();
      }, 10);
      

      setTimeout(() => {
        setShowDownloadComponent(false);
      }, 11);
    }
  }, [showDownloadComponent]);
    
  return (
    <div className="mx-[64px]"  ref={componentRef} >
      <div className="grid grid-cols-5 gap-2 grid-auto-flow mt-[24px] secondary-font text-[15px] w-full items-end secondary-font">
        <div className="main-font-bolded text-[17px]">
          Your existing product
        </div>
        <div>CO2 per KG</div>
        <div>CO2 in Period</div>
        <div>Quantity KG</div>
        <div>Price Per KG</div>
      </div>
      <div className="mt-[8px]">
        <div className="w-full secondary-font text-left grid grid-cols-5 gap-2 grid-auto-flow items-center mb-[24px] text-base text-[15px] text-Grey bg-white border rounded-xl shadow-sm leading-[20px] min-h-[80px]">
          <div className="pl-[24px] text-base leading-5 main-font-bolded text-black text-[16px]">
          {existingProduct.name}
          </div>
          <div>{existingProduct.co2perkg}</div>
          <div>{existingProduct.totalCo2}</div>
          <div>{existingProduct.weight} kg</div>
          <span>£{parseFloat(existingProduct.price).toFixed(2)}</span>
        </div>
      </div>
      <div className="w-full h-0 border border-DarkStone my-[40px]"></div>
      <div className="grid grid-cols-5 gap-2 grid-auto-flow mt-[24px] secondary-font text-[15px] w-full items-end secondary-font">
        <div className="main-font-bolded text-[17px]">New product</div>
        <div>CO2 per KG</div>
        <div>CO2 in Period</div>
        <div>Quantity KG</div>
        <div>Price Per KG</div>
      </div>
      <div className="mt-[8px]">
        <div className="w-full secondary-font text-left grid grid-cols-5 gap-2 grid-auto-flow items-center mb-[24px] text-base text-[15px] text-Grey bg-white border rounded-xl shadow-sm leading-[20px] min-h-[80px]">
          <div className="pl-[24px] text-base leading-5 main-font-bolded text-black text-[16px]">
            Sandbox
          </div>
          <div>{sandboxScenario.co2perkg}</div>
          <div>{sandboxScenario.totalCo2}</div>
          <div>{sandboxScenario.weight} kg</div>
          <div>£ {sandboxScenario.price}</div>
        </div>
      </div>
      {!showDownloadComponent && (
      <div className="mt-[40px] flex justify-start">
        <button className="grey-button mr-[16px] cursor-pointer" 
          onClick={() => {
            analyticsClickedButton('Margin Impact', 'Edit scenario');
            navigate('/sandbox/selected-products')
          }}>Edit scenario</button>
        <button className="grey-button mr-[16px] cursor-pointer" 
          onClick={() => {
            analyticsClickedButton('Margin Impact', 'Adjust the percentage');
            setShowAdjustModal(true)
          }}>Adjust the percentage</button>
        <button className="green-button mr-[16px] cursor-pointer" 
          onClick={() => {
            analyticsClickedButton('Margin Impact', 'Update Cost of Sale');
            setShowCostOfSaleModal(true)}}>Update Cost of Sale</button>
        <button className="yellow-button" 
          onClick={() => {
            analyticsClickedButton('Margin Impact', 'Save');
            handleSaveButton()}}>Save</button>
      </div>
      )}
      <div className="flex justify-between my-[15px] w-full h-[100px] bg-white box-shadow px-[40px] pt-[10px] main-font-bolded text-[17px]">
        <div>
          <div>Data Range</div>
          <div className="text-Grey mt-[5px]">{dataRange[0]}-{dataRange[1]}</div>
        </div>
        <div>
          <div>Revenue</div>
          <div className="text-Grey mt-[5px]">£ {parseFloat(marginImpactDetails.revenue).toFixed(2)}</div>
        </div>
        <div>
          <div>Cost of Sale</div>
          <div className="text-Grey mt-[5px]">£ {parseFloat(marginImpactDetails.costOfSale).toFixed(2)}</div>
        </div>
        <div>
          <div>Total Co2 kg</div>
          <div className="text-Grey mt-[5px]">{parseFloat(marginImpactDetails.totalCo2).toFixed(2)}</div>
        </div>
      </div>
      <div className="w-full h-[248px] bg-white box-shadow mb-[64px]">
        <div className="flex w-full justify-between">
          <div className="grid grid-cols-4 pl-[40px] w-3/4">
            <div className="pt-[40px]">
              <div className="main-font-bolded text-[17px] mb-[20px]">Cost of Sale Impact</div>
              <div className={`${details.costOfSaleSaving < 0 ? 'text-Promote' : 'text-Error'} main-font-bolded text-[34px]`}>£{details.costOfSaleSaving > 0 ? `+${details.costOfSaleSaving}` : `${details.costOfSaleSaving}`}</div>
              {!showDownloadComponent && (
                <div className="green-button mt-[54px] cursor-pointer w-[233px]" onClick={() => {
                  analyticsClickedButton('Margin Impact', 'Download impact margin');
                  setShowDownloadComponent(!showDownloadComponent)}}>Download impact margin</div>
              )}       
            </div>
            <div className="pt-[40px]">
              <div className="main-font-bolded text-[17px] mb-[20px]">CO2 Impact KG</div>
              <div className={`${details.co2ImpactKg < 0 ? 'text-Promote' : 'text-Error'} main-font-bolded text-[34px]`}>{details.co2ImpactKg > 0 ? `+${details.co2ImpactKg}` : `${details.co2ImpactKg}`}</div>
            </div>
            <div className="pt-[40px]">
              <div className="main-font-bolded text-[17px] mb-[20px]">Cost of Sale %</div>
              <div className={`${details.costOfSaleSaving < 0 ? 'text-Promote' : 'text-Error'} main-font-bolded text-[34px]`}>{details.costOfSaleSavingPercentage > 0 ? `+${details.costOfSaleSavingPercentage} %` : `${details.costOfSaleSavingPercentage} %`}</div>
            </div>
            <div className="pt-[40px]">
              <div className="main-font-bolded text-[17px] mb-[20px]">CO2 Impact %</div>
              <div className={`${details.co2ImpactKg < 0 ? 'text-Promote' : 'text-Error'} main-font-bolded text-[34px]`}>{details.co2Impact > 0 ? `+${details.co2Impact}` : `${details.co2Impact}`}%</div>
            </div>
            </div>
            <div className="pr-[16px]">
                <img src={details.co2ImpactKg < 0 ? HappySandbox : SadSandbox} alt='marginImpactPhoto' className="w-[236px] h-auto"/>
            </div>
        </div>
      </div>
      <CostOfSaleModal showCompareProducts={true} />
      <AdjustUnits existingProduct={existingProduct} />
      <OverwriteSandboxModal updateSandbox={handleSubmitSandbox} />
      {showDownloadComponent && (
        <>
          <SearchProducts showButtons={false} />
          <ShowSelectedProducts showForDownload={true}  />
        </>
      )}
    </div>
  );
}
