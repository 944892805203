export const baseUrl = {
  administration: 'administration',
  productMatching: 'product-matching',
  sandbox: 'sandbox',
  dashboards: 'dashboards',
  myAccount: 'my-account',
  selectProduct: 'sandbox/select-product',
  searchProducts: 'sandbox/search-products',
  savedSandboxes: 'sandbox/saved-sandboxes',
  selectedProducts: 'sandbox/selected-products',
  marginImpact: 'sandbox/margin-impact',
}