import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function MissingDataModal({showMissingDataModal, setShowMissingDataModal}) {

  return (
    <>
      <Transition.Root show={showMissingDataModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 top-2/4 max-w-[440px]"
          onClose={() => setShowMissingDataModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 flex items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 scale-95 md:scale-0">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-[440px] my-auto">
                <Dialog.Title
                  as="h3"
                  className="text-2xl text-center font-bold text-black main-font-bolded mt-[32px]">
                  Error
                </Dialog.Title>
                <div className="flex justify-center">
                  <Dialog.Description className="max-w-[366px] text-[16px] font-normal text-center mx-[37px] mt-[16px] secondary-font text-Grey leading-[22px]">
                    Percentages must be greater than 0 and the sandbox name must have less than 32 characters.
                  </Dialog.Description>
                </div>
                <div className="flex justify-center flex-wrap my-[24px]">
                  <div
                    className="grey-button mr-[16px] w-[138px] cursor-pointer"
                    onClick={() => setShowMissingDataModal(false)}>
                    Close
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
