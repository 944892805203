import { useContext } from 'react';
import { PaginationContext } from '../context/PaginationContext';

export default function usePagination() {
  const {paginationState, setPaginationState} = useContext(PaginationContext);
  function getPaginationState(pageCount) {
    setPaginationState({
      ...paginationState,
      totalPages: pageCount,
    });
  }

  function handlePageChange(page) {
    setPaginationState({ ...paginationState, currentPage: page });
  }

  return {
    paginationState,
    handlePageChange,
    getPaginationState
  };
}