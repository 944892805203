import React from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

export default function SandboxBoilerPlate({ title, showCreateButton = false, showCompareOtherProducts = false , children }) {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: title});
  const navigate = useNavigate();

  return (
    <div className="w-full h-full bg-Stone/30 relative">
      <div className="flex flex-col justify-center min-h-[150px] mx-[64px] gap-[5px] overflow-x-hidden">
        <div className="flex justify-between items-center min-h-[83px]">
          <h1 className="text-[24px] leading-[20px] main-font-bolded tracking-[0.02em] text-Black">
            {title}
          </h1>
          <title className="text-[16px] leading-[20px] main-font-bolded tracking-[0.02em] text-Black">
            {title}
          </title>
          <div className="flex justify-center items-center">
            {showCreateButton && (
              <button className="yellow-button">Create product</button>
            )}
            {showCompareOtherProducts && (
              <button className="yellow-button" onClick={() => navigate('/sandbox')}>Compare other products</button>
            )}
          </div>
        </div>
      </div>
      <div className="absolute top-[147px] w-full h-0 border border-DarkStone"></div>
      {/* subheader */}
      <div className="t-[24px] max-h-800:overflow-y-auto h-under-header overflow-x-hidden">
        {children}
      </div>
    </div>
  );
}
