import { useEffect, useContext } from 'react';
import { UserContext } from '../context/UserContext';

export default function useIsLoggedIn() {
  const { isLoggedIn, setIsLoggedIn } = useContext(UserContext);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function setToken(token) {
    localStorage.setItem('token', token);
    setIsLoggedIn(true);
  }

  function logout () {
    localStorage.removeItem('token');
    localStorage.removeItem('companyId');
    setIsLoggedIn(false);
  }
  
  return {
    isLoggedIn,
    setToken,
    logout
  }
}
