/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { searchButtons } from "../../../constants/modals";
import { SandboxContext } from "../../../context/SandboxContext";
import CreateProductModal from "./Modals/CreateProductModal";
import fetchNewSandboxProduct from "../../../utils/fetchNewSandboxProduct";
import fetchSandboxIngredients from "../../../utils/fetchSandboxIngredients";
import fetchSandboxProducts from "../../../utils/fetchSandboxProducts";
import { sandboxDetails } from "../../../constants/sandbox";
import Spinner from "../../templates/Spinner";
import SearchOptions from "./Components/SearchOptions";
import ShowProducts from "./Components/ShowProducts";
import fetchSandboxSavedProducts from "../../../utils/fetchSandboxSavedProducts";
import ShowSelectedProducts from "./Components/ShowSelectedProducts";
import BannerChooseProduct from "./Components/BannerChooseProduct";
import BannerSelectedProduct from "./Components/BannerSelectedProducts";
import RequestHelpModal from "./Modals/RequestHelpModal";
import ContactModal from "./Modals/ContactModal";
import CostOfSaleModal from "./Modals/CostOfSaleModal";
import { useNavigate } from "react-router-dom";
import { analyticsClickedButton, analyticsInputChange, returnIsValidNumberInput } from "../../../utils/utils";
import ConfirmationModal from "./Modals/ConfirmationModal";

export default function SelectedProducts() {
  const [productName, setProductName] = useState("");
  const [stateButtons, setStateButtons] = useState(searchButtons);
  const { sandbox, setSandbox, compareProducts, setCompareProducts, setShowCreateModal, showContactModal, showCostOfSaleModal, marginImpactDetails, setShowCompareProductsScreen } = useContext(SandboxContext);
  const [sandboxProduct, setSandboxProduct] = useState({
    price: "",
    weight: "",
    totalCo2: "",
    percentage: "",
  });
  const [pageDetails, setPageDetails] = useState(sandboxDetails);
  const [showConfirmationAdjustModal, setShowConfirmationAdjustModal] = useState(false);
  const [sendingRequest, setSendingRequest] = useState(false);

  const navigate = useNavigate();

  const setActiveButton = (button) => {
    //set all to false but the one clicked to true
    const state = {
      ingredient: false,
      product: false,
      savedProduct: false,
    };

    setStateButtons({
      ...state,
      [button]: true,
    });

    setPageDetails({
      ...pageDetails,
      searchTitle: button !== "savedProduct" ? button : "saved product",
    });
  };

  const handleSearchButton = async () => {
    if ((pageDetails.currentPage < pageDetails.totalPages) && pageDetails.searchTitle.length > 0) {
      setSendingRequest(true);
      const details = {
        text: productName,
        pageNumber: pageDetails.currentPage + 1,
        productsNumber: 10,
      };

      let result = null;

      //find active state button
      const activeButton = Object.keys(stateButtons).find(
        (key) => stateButtons[key] === true
      );

      if (pageDetails.products.length === 0) {
        setPageDetails({ ...pageDetails, sendingRequest: true });
      }

      if (activeButton === "ingredient") {
        result = await fetchSandboxIngredients(details);
      } else if (activeButton === "product") {
        result = await fetchSandboxProducts(details);
      } else {
        result = await fetchSandboxSavedProducts(details);
      }

      setSendingRequest(false);

      if (pageDetails.products.length === 0) {
        setPageDetails({ ...pageDetails, sendingRequest: false });
      }

      if (result?.data?.length > 0) {
        const data = result.data.map((item) => {
          item.selected = compareProducts.find((product) => product.product_id === item.product_id) ? true: false;
          item.percentage = 0;
          item.co2perkg = Number(item.co2perkg).toFixed(2);

          return item;
        });

        if (pageDetails.products.length === 0) {
          setPageDetails({
            ...pageDetails,
            products: data,
            hideButtons: true,
            currentPage: result.meta.pagination.page,
            totalPages: result.meta.pagination.pageCount,
          });
        } else {
          setPageDetails({
            ...pageDetails,
            products: [...pageDetails.products, ...data],
            currentPage: result.meta.pagination.page,
          });
        }
      }
    }
  };

  const selectProduct = (index, status) => {
    const newProducts = pageDetails.products.map((item, position) => {
      if (position === index) {
        item.selected = status;
      }

      return item;
    });

    setPageDetails({ ...pageDetails, products: newProducts });
  };

  const checkIfSearchProductSelected = () => {
    if (pageDetails.products.length > 0) {
      const selectedProduct = pageDetails.products.find(
        (item) => item.selected === true
      );

      return selectedProduct ? true : false;
    }

    return false;
  };

  const modelData = async (data) => {
    setPageDetails({ ...pageDetails, sendingRequest: true });
    const result = await fetchNewSandboxProduct(data);
    result.product = result.product_name;
    result.price = result.unit_price;
    result.percentage = 0;
    result.product_id = result.id;
    result.collectionType = 'sandbox'

    delete result.unit_price;
    delete result.product_name;
    delete result.id;

    setCompareProducts([...compareProducts, result]);
    setPageDetails({
      ...pageDetails,
      showFindProduct: false,
      sendingRequest: false,
    });
  };

  const addProductToSelectedProducts = () => {
    let foundSelectedProduct = pageDetails.products.filter(
      (item) => item.selected === true && !compareProducts.find((product) => product.product_id === item.product_id)
    );

    foundSelectedProduct.percentage = 0;

    setCompareProducts([...compareProducts, ...foundSelectedProduct]);

    setProductName("");
    setPageDetails({ ...pageDetails, products: [], showFindProduct: false });
    setShowCompareProductsScreen(true);
    setStateButtons(searchButtons);
  };

  const handleClearButton = () => {
    setProductName("");
    setPageDetails(sandboxDetails);
    setStateButtons(searchButtons);
    setShowCompareProductsScreen(false);
  };

  const removeProductFromSelectedProducts = (index) => {
    const newSelectedProducts = compareProducts.filter((product, i) => i !== index);

    if (newSelectedProducts.length === 0) {
      setPageDetails(sandboxDetails);
    }

    setCompareProducts(newSelectedProducts);
  };

  const clearSelectedProducts = () => {
    setCompareProducts([]);
    setPageDetails({
      ...pageDetails,
      showFindProduct: true,
      hideButtons: false,
    });
    handleClearButton();
  };

  const handlePriceChange = (value) => {
    const checkedInput = returnIsValidNumberInput(value, sandboxProduct.price);

    if (!checkedInput) return;

    setSandbox({ ...sandbox, price: value.length === 1 && value === "." ? "0." : value.replace(",", "."), });
    setSandboxProduct({
      ...sandboxProduct,
      price: value.length === 1 && value === "." ? "0." : value.replace(",", "."),
    });
  }

  const returnPage = (value) => {
    if (value) {
      setPageDetails({ ...pageDetails, showFindProduct: false });
    } else {
      setProductName("");
      setPageDetails({ ...pageDetails, showFindProduct: true });
      setStateButtons(searchButtons);
    }
  }

  const handleViewSandboxButton = () => {
    if (compareProducts.length > 0) {
      setPageDetails({ ...pageDetails, showFindProduct: false });
    }
  }

  const adjustProducts = () => {
    navigate('/sandbox/search-products');
  }

  const sandboxProductUpdate = (value) => {
    setSandboxProduct({ ...sandboxProduct, price: value });
    setSandbox({ ...sandbox, price: value.length === 1 && value === "." ? "0." : value.replace(",", "."), });
  }

  const updateAnalytics = () => {
    analyticsClickedButton("Search for a product", 'Run Search');
    analyticsInputChange("Search for a product", 'Search substitution', productName);

    let activeButton = null;

    for (const key in stateButtons) {
      if (stateButtons[key] === true) {
        activeButton = key;
      }
    }

    analyticsClickedButton("Searched substitution", activeButton);
  }

  useEffect(() => {
    if (Object.keys(sandbox).length > 0) {
      let product = {...sandbox};
      product.weight = (sandbox.weight * sandbox.toSandboxPercentage) / 100;
      product.percentage = sandbox.toSandboxPercentage;
      product.totalCo2 = sandbox.co2perkg * product.weight;
      delete product.toSandboxPercentage; 

      setSandboxProduct(product); 
    } else {
      navigate('/sandbox');
    }

    if(String(marginImpactDetails.costOfSale).length > 0) {
      setPageDetails({...pageDetails, showFindProduct: false});
    }
  }, []);

  return (
    <>
      <div className="mx-[64px]">
        <div className="grid grid-cols-9 grid-auto-flow mt-[24px] secondary-font text-[15px] w-full text-left items-end">
          <div className="font-bold text-[17px] text-black col-span-2 main-font-bolded leading-5">
            Your existing product
          </div>
          <div>CO2 per KG</div>
          <div>CO2 KG in Period</div>
          <div>Quantity KG</div>
          <div></div>
          <div>Price Per KG</div>
          <div className="col-span-2"></div>
        </div>
        <div className="mb-[20px] mt-[6px]">
          <div className="w-full secondary-font text-left leading-5 grid grid-cols-9 grid-auto-flow items-center mb-[24px] text-base text-[15px] text-Grey bg-white border rounded-xl shadow-sm min-h-[80px]">
            <div className="pl-[24px] text-base leading-5 col-span-2 main-font-bolded text-black text-[16px]">
              {sandboxProduct.name}
            </div>
            <div className="text-[15px]">{sandboxProduct.co2perkg}</div>
            <div className="text-[15px]">{parseFloat(sandboxProduct.totalCo2).toFixed(2)}</div>
            <div className="text-[15px]">{parseFloat(sandboxProduct.weight).toFixed(2)} kg</div>
            <div></div>
            <div className="relative max-w-[104px] mr-[37px] min-w-[50px]">
              <input
                className="w-full border rounded-[8px] py-[10px] px-[12px] pl-[20px] text-[15px]"
                value={sandboxProduct.price}
                onChange={(e) => handlePriceChange(e.target.value)}
              />
              <span className="absolute left-[10px] top-[10px]">£</span>
            </div>

            <div className="col-span-2 flex justify-end mr-[20px]">
              <div className="grey-button cursor-pointer" onClick={() => setShowConfirmationAdjustModal(true)}>Adjust products</div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-0 border border-DarkStone"></div>
      <div
        className={`${!checkIfSearchProductSelected() ? "max-h-[calc(100%-220px)]" : "max-h-[calc(100%-330px)]"} list-scrollbar mt-[56px] py-1 pb-[10px]`}>
        {!pageDetails.showFindProduct ? (
          <>
            <ShowSelectedProducts
              removeProduct={removeProductFromSelectedProducts}
              clearSelectedProducts={clearSelectedProducts}
              resetPageDetails={handleClearButton}
              updateTotalPercentage={(value) =>
                setPageDetails({ ...pageDetails, totalPercentage: value })
              }
            />
            <BannerSelectedProduct
              compareProducts={compareProducts}
              resetPageDetails={handleClearButton}
              totalPercentage={pageDetails.totalPercentage}
            />
          </>
        ) : (
          <div>
            <div className="flex flex-wrap justify-between items-end mb-[16px]">
              <div className={`${pageDetails.searchTitle.length > 0 ? "text-[15px]": "text-2xl"} main-font-bolded leading-5 px-[64px] flex items-end"`}>
                <div>{pageDetails.searchTitle.length > 0 ? `Results for ${pageDetails.searchTitle} search` : "Search"}</div>
              </div>
              {pageDetails.hideButtons && (
                <div className="mr-[60px]">
                  <div className="text-[16px] text-Grey secondary-font">
                    Can't find the product you are looking for?
                  </div>
                </div>
              )}
            </div>
            {!pageDetails.hideButtons && (
              <SearchOptions
                stateButtons={stateButtons}
                setActiveButton={setActiveButton}
              />
            )}
            {/* input and some buttons  */}
            <div className="flex flex-wrap justify-between pl-[64px]">
              <div className="flex flex-row flex-start items-center">
                <div className="w-[476px] h-[56px]">
                  <div className="relative rounded-md">
                    <input
                      type="text"
                      name="productName"
                      id="productName"
                      className="block w-[476px] h-[56px] secondary-font text-base rounded-lg border-0 pl-[16px] text-black ring-1 ring-inset ring-Grey placeholder:text-black focus:ring-1 focus:ring-Grey focus:ring-inset"
                      value={productName}
                      onChange={(e) => setProductName(e.target.value)}
                    />
                    <button
                      type="button"
                      className="pointer-events-auto absolute inset-y-0 right-0 flex items-center pr-[24px] text-gray-500 secondary-font text-[15px] focus:outline-none"
                      onClick={() => handleClearButton()}>
                      Clear
                    </button>
                  </div>
                </div>
                {!pageDetails.hideButtons ? (
                  <div
                    className="grey-button bg-DarkStone cursor-pointer ml-[32px]"
                    onClick={() => {
                      analyticsClickedButton("Search for a product", 'Create New Product');
                      setShowCreateModal(true);
                      }}>
                    Create New
                  </div>
                ) : (
                  <div className="secondary-font text-[15px] text-Grey ml-[24px] pl-[64px]">
                    {pageDetails.products.length} RESULTS
                  </div>
                )}
              </div>
              {pageDetails.hideButtons && (
                <div className="flex justify-start mr-[190px]">
                  <div
                    className="yellow-button max-h-[40px] max-w-[200px] text-[16px] main-font"
                    onClick={() => {
                      analyticsClickedButton("Search for a product", 'Create New Product');
                      setShowCreateModal(true);
                      }}>
                    Create New Product
                  </div>
                </div>
              )}
            </div>
            {!pageDetails.hideButtons && (
              <div className="mt-[24px] ml-[64px] flex flex-start">
                <div
                  className="yellow-button cursor-pointer"
                  onClick={() => {
                    updateAnalytics();
                    handleSearchButton();
                    }}>
                  Run Search
                </div>
                <div className={`${compareProducts.length > 0 ? 'cursor-pointer' : 'cursor-default opacity-60'} green-button ml-2`} 
                  onClick={() => {
                    analyticsClickedButton("Search for a product", 'View Sandbox');
                    handleViewSandboxButton();
                    }}>
                  View Sandbox
                </div>
              </div>
            )}
            {/*  */}
          </div>
        )}
        {pageDetails.sendingRequest && <Spinner />}
        {pageDetails.hideButtons && pageDetails.showFindProduct && (
          <ShowProducts
            stateButtons={stateButtons}
            pageDetails={pageDetails}
            sendingRequest={sendingRequest}
            selectProduct={selectProduct}
            handleSearchButton={handleSearchButton}
          />
        )}
      </div>
      <CreateProductModal handleModalData={modelData} />
      <RequestHelpModal />
      {showContactModal && <ContactModal returnPage={returnPage}/>}
      {showCostOfSaleModal && <CostOfSaleModal showCompareProducts={false} sandboxProduct={sandboxProduct} sandboxProductUpdate={sandboxProductUpdate} />}
      <ConfirmationModal 
        showConfirmationModal={showConfirmationAdjustModal} 
        adjustProducts={adjustProducts} 
        setShowConfirmationModal={setShowConfirmationAdjustModal} 
        type='adjust' 
        buttonName='Adjust'
        title='Adjust products'/>
      <BannerChooseProduct
        addProductToSelectedProducts={addProductToSelectedProducts}
        checkIfSearchProductSelected={checkIfSearchProductSelected}
      />
    </>
  );
}