import { useState, createContext } from 'react';

export const MatchingEntryContext = createContext({
  match: {},
  setMatch: () => {/* this is intentional */},
  confirmed: false,
  setConfirmed: () => {/* this is intentional */},
  isSearching: false,
  setIsSearching: () => {/* this is intentional */},
  displayPossibleMatchesMetadata: false,
  setDisplayPossibleMatchesMetadata: () => {/* this is intentional */}
});

export default function MatchingEntryProvider({ children }) {
  const [match, setMatch] = useState();
  const [confirmed, setConfirmed] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [displayPossibleMatchesMetadata, setDisplayPossibleMatchesMetadata] = useState(false);

  return (
    <MatchingEntryContext.Provider value={{match, setMatch, confirmed, setConfirmed, isSearching, setIsSearching, displayPossibleMatchesMetadata, setDisplayPossibleMatchesMetadata}}>
      {children}
    </MatchingEntryContext.Provider>
  );
}
