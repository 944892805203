import React, { useEffect, useState } from "react";
import ArrowSvg from "../../../../assets/svgs/UploadCSV/ArrowSvg";
import Calendar from "../../../../assets/svgs/Sandbox/Calendar";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../assets/styles/calendarStyles.css"

export default function CalendarInput({setRangeFilter, rangeError, rangeFilter}) {
  const [dateRange, setDateRange] = useState([new Date(), null]);
  const [showingDates, setShowingDates] = useState(rangeFilter);

  useEffect(() => {
    if(dateRange[1]) {
      const startDate = moment(dateRange[0]).format("DD.MM.YYYY");
      const endDate = moment(dateRange[1]).format("DD.MM.YYYY");

      const startShowingDate = moment(dateRange[0]).format("MM.YYYY");
      const endShowingDate = moment(dateRange[1]).format("MM.YYYY");

      setShowingDates([startShowingDate, endShowingDate]);
      setRangeFilter([startDate, endDate]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange])

  return (
    <DatePicker
      selected={dateRange[0]}
      showMonthYearPicker
      selectsRange={true}
      startDate={dateRange[0]}
      endDate={dateRange[1]}
      customInput={
        <div className={`${rangeError && 'border-red-500'} flex flex-row items-center justify-between w-[252px] h-[56px] border rounded-[8px] text-[#585858] text-base shadow-md secondary-font`}>
          <div className="flex flex-row pl-[18px]">
            <div>
              <Calendar />
            </div>
            <div className="pl-[5px]">{showingDates !== null && showingDates[1] ? `${showingDates[0]}-${showingDates[1]}`: 'Date filter'}</div>
          </div>
          <div className="pr-5">
            <ArrowSvg color="black" />
          </div>
        </div>
      }
      onChange={(update) => {
        setDateRange(update);
      }}
    />
  );
}
