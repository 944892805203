import useActiveComponent from "../../../hooks/useActiveComponent";
import {productMatchingComponents} from '../../../constants/productMatchingComponents.js'
import useProductMatching from "../../../hooks/useProductMatching";
import usePagination from "../../../hooks/usePagination";

export default function Header() {
  const {component, handleClick} = useActiveComponent();
  const {reset, save, confirmedEntries} = useProductMatching();
  const {handlePageChange} = usePagination();

  function handleComponentChange(event) {
    handleClick(event);
    reset();
    handlePageChange(1);
  }

  return (<>
      <div className='flex flex-col justify-end min-h-[150px] mx-[64px] gap-[5px]'>
        <div className='flex justify-between items-center min-h-[83px]'>
          <h1
            className='text-[24px] leading-[44px] main-font-bolded tracking-[0.02em] text-Black'>{component.id === productMatchingComponents.weightMatch.id ? 'Resolve anomalies' : 'Abstract Matching'}</h1>
          <div className='flex justify-center items-center'>
            <button className='green-button' onClick={save} disabled={Object.keys(confirmedEntries).length < 1}> {/* remember to add post request when backend is done */}
              Save progress
            </button>
          </div>
        </div>
        <div className='flex flex-row justify-start items-center gap-[48px] z-[1]'>
          {Object.values(productMatchingComponents).map(matchingComponent => <button id={matchingComponent.id} key={matchingComponent.id} className={component.id === matchingComponent.id ? 'active-header-button' : 'inactive-header-button'} onClick={handleComponentChange} disabled={matchingComponent.id === component.id}>
            {matchingComponent.tabText}
          </button>)}
        </div>
      </div>
      <div className='absolute top-[147px] w-full h-0 border border-DarkStone'></div>
    </>)
}