/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { returnIsValidNumberInput, returnIsValidPercentageInput } from "../../../../utils/utils";
import { SandboxContext } from "../../../../context/SandboxContext";
import ConfirmationModal from "../Modals/ConfirmationModal";

export default function ShowSelectedProducts({ removeProduct = undefined, updateTotalPercentage = undefined, clearSelectedProducts = undefined, showForDownload = false }) {
  const [products, setProducts] = useState([]);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const {sandbox, compareProducts, setCompareProducts} = useContext(SandboxContext);
  const [showConfirmationModal, setConfirmationModal] = useState(false);

  const calculateTotalPercentage = (data) => {
    const percentage = data.reduce((acc, product) => {
      if (product.percentage.toString().length > 0) {
        return acc + Number(product.percentage);
      }

      return acc;
    }, 0);

    if (!showForDownload) {
      updateTotalPercentage(percentage);
    }
    
    setTotalPercentage(percentage);
  }

  const removeProductFromList = (index) => {
    const newProducts = products.filter((product, i) => i !== index);

    calculateTotalPercentage(newProducts);
    setProducts(newProducts);
    removeProduct(index);
  }

  const clearProducts = () => {
    clearSelectedProducts();
    setProducts([]);
  }

  const updateProductPrice = (index, price) => {
    const checkedInput = returnIsValidNumberInput(price, products[index].price);

    if (!checkedInput) return;

    const mappedProducts = products.map((product, i) => {
      if (i === index) {
        product.price = price.length === 1 && price === "." ? "0." : price.replace(",", ".")
      }

      return product;
    });

    setCompareProducts(mappedProducts);
    setProducts(mappedProducts);
  }

  const handlePercentage = (index, percentage) => {
    const checkedInput = returnIsValidPercentageInput(percentage);

    if (!checkedInput) return;

    const mappedProducts = products.map((product, i) => {
      if (i === index) {
        const sandboxWeight = (sandbox.weight * sandbox.toSandboxPercentage) / 100;
        product.percentage = percentage;
        product.weight = parseFloat(sandboxWeight * (percentage / 100)).toFixed(2);
        product.totalCo2 = parseFloat(product.weight * product.co2perkg).toFixed(2);
      }

      return product;
    });

    calculateTotalPercentage(mappedProducts);

    setCompareProducts(mappedProducts);
    setProducts(mappedProducts);
  };

  useEffect(() => {
    const sandboxWeight = (sandbox.weight * sandbox.toSandboxPercentage) / 100;

    const mappedProducts = compareProducts.map((product) => {
      product.price = product?.price || 0;
      product.weight = parseFloat((sandboxWeight * product.percentage) / 100).toFixed(2);
      product.totalCo2 = parseFloat(product.weight * product.co2perkg).toFixed(2);
      product.co2perkg = parseFloat(product.co2perkg).toFixed(2);
      return product;
    });

    calculateTotalPercentage(mappedProducts);
    setProducts(mappedProducts);
  }, []);

  useEffect(() => {
    if (products.length) {
      calculateTotalPercentage(products);
    }
  }, [sandbox, compareProducts]);

  return (
    <div className={`${ showForDownload ? ' mt-[200px] pt-[50px]' : 'list-scrollbar'} ml-[64px] mr-[64px] main-font-bolded text-[17px] max-h-[calc(100vh-480px)]`}>
      <div className="flex justify-between">
        <h2>Search for a new product</h2>
        {products.length > 0 && (
          <div className="grey-button cursor-pointer w-full max-w-[150px] text-[15px] mr-[20px]" onClick={() => setConfirmationModal(true)}>Clear products</div>
        )}
      </div>
      <div className="grid grid-cols-9 gap-2 grid-auto-flow mt-[24px] secondary-font text-[15px] w-full items-end">
        <div className="pl-[24px] col-span-2">Product Name</div>
        <div>CO2 per KG</div>
        <div>CO2 KG in Period</div>
        <div>Quantity KG</div>
        <div className="flex justify-center">
          <div>%</div>
        </div>
        <div>Price Per KG</div>
        <div className="col-span-2"></div>
      </div>

      <div>
      {products.map((product, index) => (
        <div className="mb-[20px] mt-[6px]" key={index}>
          <div className="w-full secondary-font text-left gap-2 grid grid-cols-9 grid-auto-flow items-center mb-[24px] text-base text-[15px] text-Grey bg-white border rounded-xl shadow-sm leading-[20px] min-h-[80px]">
            <div className="pl-[24px] text-base leading-5 col-span-2 main-font-bolded text-black text-[16px]">
              {product.product}
            </div>
            <div>{product.co2perkg}</div>
            <div>{product.totalCo2}</div>
            <div>{product.weight} kg</div>
            <div className="flex flex-row justify-center">
              <div className="max-w-[56px]">
                <input
                  className="w-full border rounded-[8px] py-[10px] px-[12px] pl-[8px] text-[15px]"
                  value={product.percentage}
                  onChange={(e) => handlePercentage(index, e.target.value)}
                />
              </div>
            </div>
            <div className="relative max-w-[104px] mr-[37px] min-w-[50px]">
              <input
                className="w-full border rounded-[8px] py-[10px] px-[12px] pl-[20px] text-[15px]"
                value={product.price}
                onChange={(e) => updateProductPrice(index, e.target.value)}
              />
              <span className="absolute left-[10px] top-[10px]">£</span>
            </div>
            <div className="col-span-2 flex justify-end mr-[20px]">
              <div className="grey-button cursor-pointer text-[15px] leading-5 w-full max-w-[147px]" onClick={() => removeProductFromList(index)}>Clear product</div>
            </div>
          </div>
        </div>
      ))}
      </div>

      <div className="flex justify-center">
        <div className="mt-[40px] mb-[58px] max-w-[548px] w-full h-[80px] bg-white box-shadow p-[16px]">
          <div className="flex justify-between">
            <div className="w-full">
              <div className="mb-[8px] secondary-font font-bold text-[16px]">Percentage total:</div>
              <div className="secondary-font text-[15px]">Percentage must be 100% before running this sandbox</div>
            </div>
            <div className="flex justify-center w-[100px]">
              <div className={`${totalPercentage === 100 ? 'bg-Promote text-white' : 'bg-Lightgrey'} grey-button max-h-[56px] w-full max-w-[92px]`}>{totalPercentage}%</div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModal 
        showConfirmationModal={showConfirmationModal} 
        clearProducts={clearProducts} 
        setShowConfirmationModal={setConfirmationModal} 
        type='products' 
        buttonName='Clear'
        title='Clear products'/>
    </div>
  );
}
