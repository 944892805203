import { useEffect, useRef, useState } from 'react';
import Lottie from 'lottie-react';
import {classNames} from '../../../utils/utils';
import fetchDashboard from '../../../utils/fetchDashboard';
import dashboardSpinner from '../../../../assets/animations/dashboardSpinner.json';

export default function UnfilteredDashboard({ slug }) {
  const iFrame = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    !isLoading && setIsLoading(true);
    fetchDashboard(slug).then(({ iframeUrl }) => {
      iFrame.current.src = iframeUrl;
      iFrame.current.onload = () => setIsLoading(false);
    });
  }, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

  return <>
    {isLoading && <Lottie animationData={dashboardSpinner} loop={true} className='h-under-header bg-Stone/30' />}
    <div className={classNames(isLoading ? '' : 'hidden', 'w-full h-under-header bg-Stone/30')} />
    <iframe title='metabase-dashboard' ref={iFrame} className='w-full h-under-header bg-White' />
  </>;
}