import React, { Fragment, useContext, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Formik, Form } from "formik";
import { createProductModalInitialState, createProductModalValidationSchema } from "../../../../constants/modals";
import FormInput from "./FormInput";
import { SandboxContext } from "../../../../context/SandboxContext";

export default function CreateProductModal({ handleModalData }) {
  const { showCreateModal, setShowCreateModal, setCreateProductData } = useContext(SandboxContext);
  const [showUncompletedDataModal, setShowUncompletedDataModal] = useState(false);

  function checkIfValidForSubmitting(errors) {
    let isValid = true;

    if (Object.keys(errors).length !== 0) {
      return false;
    }

    return isValid;
  }

  function checkIfValidForSending(errors) {
    let isValid = false;

    let contactErrors = Object.keys(errors);

    if (contactErrors.length === 2 && contactErrors.includes('carbonFootprint') && contactErrors.includes('unitPrice')) {
      return true;
    }

    return isValid;
  }

  const handleUncompletedDataModal = () => {
    setShowUncompletedDataModal(true);
  };

  const updateCreateProductData = (key, value) => {
    setCreateProductData((prev) => ({ ...prev, [key]: value }));
  };
    
  return (
    <>
      <Formik
        initialValues={createProductModalInitialState}
        validationSchema={createProductModalValidationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={async (values, {resetForm}) => {
          handleModalData(values);
          setShowCreateModal(false);
          resetForm(createProductModalInitialState);
        }}>
        {({ setValues, values, errors, handleBlur, handleChange, resetForm }) => (
          <Transition.Root show={showCreateModal} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-20 top-2/4 max-w-[440px]"
              onClose={() => {
                setShowCreateModal(false);
                setValues(createProductModalInitialState);
                setShowUncompletedDataModal(false);
              }}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full md:items-end justify-center p-4 text-center items-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 scale-95 md:scale-0">
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-[440px] px-[40px] py-[32px] my-auto">
                      <div>
                        <div className="text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-2xl text-center font-bold text-black main-font-bolded">
                            Create product
                          </Dialog.Title>
                          <Form className="mt-[24px]">
                            <div className="mb-[16px]">
                              <FormInput
                                  name={"name"}
                                  label={"Product name*"}
                                  value={values.name}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    updateCreateProductData("name", e.target.value);
                                    handleChange(e);
                                  }}
                                  showError={showUncompletedDataModal && values.name === "" ? true : false}
                                />
                            </div>
                            <div className="mb-[16px]">
                              <FormInput
                                  name={"weight"}
                                  label={"Product weight (KG)*"}
                                  value={values.weight}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    updateCreateProductData("weight", e.target.value);
                                    handleChange(e);
                                  }}
                                  inputType="number"
                                  showError={showUncompletedDataModal && values.weight === "" ? true : false}
                                />
                            </div>
                            <div className="mb-[16px]">
                              <FormInput
                                  name={"carbonFootprint"}
                                  label={"Carbon Footprint per KG*"}
                                  value={values.carbonFootprint}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    updateCreateProductData("carbonFootprint", e.target.value);
                                    handleChange(e);
                                  }}
                                  readyToContactSupport={checkIfValidForSending(errors)}
                                  // showError={showUncompletedDataModal && values.carbonFootprint === "" ? true : false}
                                  handleUncompletedDataModal={handleUncompletedDataModal}
                                  showInfoIcon={true}
                                  resetForm={resetForm}
                                  inputType="number"
                                />
                            </div>
                            <div className="mb-[16px]">
                              <FormInput
                                  name={"unitPrice"}
                                  label={"Unit Price*"}
                                  value={values.unitPrice}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    updateCreateProductData("unitPrice", e.target.value);
                                    handleChange(e);
                                  }}
                                  inputType="number"
                                  // showError={showUncompletedDataModal && values.unitPrice === "" ? true : false}
                                />
                            </div>
                            <div className="mb-[16px]">
                              <FormInput
                                  name={"sku"}
                                  label={"SKU"}
                                  value={values.sku}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    updateCreateProductData("sku", e.target.value);
                                    handleChange(e);
                                  }}
                                />
                            </div>
                            <div className="mb-[16px]">
                              <FormInput
                                  name={"manufacturer"}
                                  label={"Manufacturer"}
                                  value={values.manufacturer}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    updateCreateProductData("manufacturer", e.target.value);
                                    handleChange(e);
                                  }}

                                />
                            </div>
                            <div>
                              <FormInput
                                  name={"supplier"}
                                  label={"Supplier"}
                                  value={values.supplier}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    updateCreateProductData("supplier", e.target.value);
                                    handleChange(e);
                                  }}
                                />
                            </div>
                            <div className="flex justify-center mt-[32px]">
                              <button
                                type="submit"
                                className={`${
                                  !checkIfValidForSubmitting(errors) &&
                                  "opacity-60 text-Grey cursor-default"
                                } green-button`}>
                                Create product
                              </button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        )}
      </Formik>
      {/* <ErrorMsg /> */}
    </>
  );
}
