import Header from '../parts/ProductMatchingPage/Header';
import BeforeMain from '../parts/ProductMatchingPage/BeforeMain';
import useActiveComponent from '../../hooks/useActiveComponent';
import ProductMatchingComponentProvider from '../../context/ProductMatchingComponentContext';
import ProductMatchingProvider from '../../context/ProductMatchingContext';
import PaginationProvider from '../../context/PaginationContext';
import useFetchPaginatedEntries from "../../hooks/useFetchPaginatedEntries";

function ProductMatchingComponent() {
  const {component} = useActiveComponent();
  const {isLoading, totalEntries, totalEntriesPerPage} = useFetchPaginatedEntries(component.fetchFn);

  return (<div className='w-full h-full bg-Stone/30 relative'>
    <Header/>
    <BeforeMain totalCount={totalEntries} totalCountPerPage={totalEntriesPerPage}/>
    {component.tabContent(isLoading)}
  </div>);
}

export default function ProductMatching() {
  return <ProductMatchingComponentProvider>
    <ProductMatchingProvider>
      <PaginationProvider>
        <ProductMatchingComponent/>
      </PaginationProvider>
    </ProductMatchingProvider>
  </ProductMatchingComponentProvider>;
}
