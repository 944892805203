import useUploadCSVForm from "../../../hooks/useUploadCSVForm";

export default function UploadSuccessScreen() {
  const {resetState} = useUploadCSVForm();

  return <div className='-top-0 absolute w-full h-full bg-White'>
      <div className='h-full flex flex-col justify-center items-center text-center bg-Stone/30'>
        <img className='w-[400px] h-[400px]' src="/imgs/Zedible_Branding_Offset.png" alt=""/>
        <h1
          className='max-w-[512px] main-font-bolded text-[34px] leading-[44px] text-Black tracking-[-0.02em] mb-[8px]'>
          Hey, your files have been uploaded!
        </h1>
        <button
          className='bg-Stone rounded-[8px] main-font-bolded text-[15px] leading-[20px] text-Black py-[8px] px-[26px]'
          onClick={() => resetState(true)}
        >
          Close
        </button>
      </div>
    </div>
}