import usePagination from '../../hooks/usePagination';
import {useEffect, useRef, useState} from 'react';
import {withModal} from "../../utils/utils";

export default function Pagination({modalTrigger}) {
  const {paginationState, handlePageChange} = usePagination();
  const [auxiliaryState, setAuxiliaryState] = useState(paginationState.currentPage);
  const inputRef = useRef(null);

  useEffect(() => {
    setAuxiliaryState(paginationState?.currentPage)
  }, [paginationState?.currentPage])

  function handleActions(action) {
    if (action === 'next' && paginationState.currentPage < paginationState.totalPages) {
      withModal(modalTrigger, handlePageChange, paginationState.currentPage + 1)
    }
    if (action === 'prev' && paginationState.currentPage > 1) {
      withModal(modalTrigger, handlePageChange, paginationState.currentPage - 1)
    }
  }

  function handleInputChange(e) {
    if (e.target.value.match(/[^\d+\b]/)) return;
    if (e.target.value < 0) return setAuxiliaryState(1);
    if (e.target.value === '0') return setAuxiliaryState(1);
    if (e.target.value > paginationState.totalPages) return setAuxiliaryState(paginationState?.totalPages);
    setAuxiliaryState(Number(e.target.value));
  }

  function handleSubmit(e) {
    if (!!e.target.value.match(/^\d+$/g) && e.key === 'Enter') {
      handlePageChange(auxiliaryState);
      inputRef.current.blur();
    }
  }

  if (paginationState.totalPages <= 1) return null;

  return <div className='flex justify-between h-[32px]'>
    <div className='flex justify-center items-center gap-[8px] flex-1'>
      <button type='button'
              className='w-[32px] h-[32px] rounded-[8px] text-Black font-[14px] leading-[20px] flex justify-center items-center'
              onClick={() => handleActions('prev')}>
        <svg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M7 1L1 6L7 11' stroke='#F4C842' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>
      </button>
      <input type='text' ref={inputRef}
             className='w-[40px] h-[32px] rounded-[8px] text-Black font-[14px] leading-[20px] flex justify-center items-center remove-focus outline-none text-center'
             value={auxiliaryState} onChange={handleInputChange} onKeyDown={handleSubmit}/>
      <button type='button'
              className='w-[32px] h-[32px] rounded-[8px] text-Black font-[14px] leading-[20px] flex justify-center items-center'
              onClick={() => handleActions('next')}>
        <svg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M1 1L7 6L1 11' stroke='#F4C842' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
        </svg>
      </button>
    </div>
    <span
      className='flex text-[14px] leading-[20px] font-normal text-Black items-center justify-self-end'>Page {paginationState?.currentPage} of {paginationState?.totalPages}</span>
  </div>
}