export default function InfoSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1401_6652)">
        <circle cx="12" cy="12" r="11.5" stroke="#585858" />
        <path
          d="M11.4495 7.3625V6.125H12.552V7.3625H11.4495ZM11.4495 17V8.9H12.552V17H11.4495Z"
          fill="#585858"
        />
      </g>
      <defs>
        <clipPath id="clip0_1401_6652">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
